<template>
  <div>
    <draw :drawShow="productShow" :okName="'提交反馈'" @drawconfirm="drawconfirm" @drawcancel="drawcancel">
      <div slot class=" drawContent">
        <img class="banner" src="@/assets/img1/product.png" alt="">
        <div class="bannerTxt">您是否愿意<br>向您的朋友、合作伙伴等推荐使用“知也云查重系统”？</div>
        <div class="bannnerInfo">希望您能与我们分享真实的产品使用感受。</div>
        <ul class="flexsb lv">
          <li :class="evaluate.leve == item ? 'active' : ''" v-for="(item) in NumberList" :key="item"
            @click="evaluate.leve = item">{{
              item }}</li>
        </ul>
        <div class="flexsb likeTxt">
          <div>（极不愿意）</div>
          <div>（非常愿意）</div>
        </div>
        <div class="bannnerT1">更多反馈（选填）：</div>
        <el-input resize="none" type="textarea" :rows="6" placeholder="感谢反馈，期待您分享更多使用感受和建议，让我们为您提供更好的服务"
          v-model="evaluate.memo">
        </el-input>
      </div>
    </draw>
  </div>
</template>

<script>
import draw from "@/component/draw"
export default {
  props: ['productShow', 'okbtnName'],
  components: { draw },
  data () {
    return {
      NumberList: [0, 1, 2, 3, 4, 5,6,7,8,9,10],
      evaluate:{
        leve: null,
        memo: ''
      }
    }
  },
  methods: {
    drawconfirm () {
      this.$emit('cancelEvent',this.evaluate);
      this.evaluate.memo = ''
      this.evaluate.leve = null
    },
    drawcancel () {
      this.evaluate.memo = ''
      this.evaluate.leve = null
      this.$emit('cancelEvent')
    },
  },
}
</script>

<style lang="less" scoped>
.drawContent {
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 76px - 118px);

  .banner {
    width: 100%;
    margin-bottom: 10px;
  }

  .bannerTxt {
    font-size: 19px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2C2D3A;
    line-height: 29px;
  }

  .bannnerInfo {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2C2D3A;
    line-height: 20px;
    margin: 10px 0 18px;
  }

  ul {
    li {
      cursor: pointer;
      user-select: none;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      background: rgba(224, 234, 241, 0.3);
      border: 1px solid #E0EAF1;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2C2D3A;
      line-height: 32px;
      text-align: center;
      margin-right:6px;
    }
    li:last-child{
      margin-right:0;
    }

    .active {
      background: rgba(0, 107, 255, 0.1);
      border: 1px solid #006BFF;
    }
  }

  .likeTxt {
    margin: 20px 0;

    div {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 17px;
    }

  }

  .bannnerT1 {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2C2D3A;
    line-height: 17px;
    margin-bottom: 12px;
  }
}
.lv{flex-wrap: wrap;justify-content:inherit}
</style> 