import Vue from 'vue'
import Vuex from 'vuex'
import { userInfo } from '@/api/saasuser'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    dialogout: false,
    userType: '',
    userInfo: {},
    messageNum:0
  },
  mutations: {
    Setdialogout (state, payload) {
      state.dialogout = payload
    },
    setMessageNum (state, payload) {
      state.messageNum = payload
    },
    getUserInfo (state, payload) {
      state.userInfo = payload
    },
    getUsertype (state, payload) {
      state.userType = payload
    }
  },
  actions: {
    async getUserInfo (store, payload) {
      let { data } = await userInfo()
      store.commit('getUserInfo', data)
    }
  }
})
