// 1.引入路由
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 引入组件.
import LoginView from '@/views/login'
import CheckView from '@/views/check_index'
import Register from '@/views/register'
import ResetPassword from '@/views/resetPassword'
import Orgview from '@/views/org_index'

// 查重服务
import chosePay from '@/component/orderpay/chosePay'

// 企业中心
import basicInformation from '@/views/org_index/component/basicInformation'
import myorder from '@/views/org_index/component/myOrder'
import invoice from '@/views/org_index/component/invoice'
import accountnumber from '@/views/org_index/component/accountNumber'

import duplicatecheckDetail from '@/views/duplicatecheckDetail'

// 路由配置规则
const routes = [
  {
    path: '*',
    redirect: '/login',
  },
  // {
  //   path: '/redirect',
  //   name: 'redirect',
  //   component: () => import('@/views/redirect'),
  // },
  {
    path: '/down',
    name: 'down',
    component: () => import('@/views/download.vue'),
    meta: {
      title: '下载',
    },
  },
  // 微信扫码登录成功获取token跳转页
  {
    path: '/wxlogin',
    name: 'wxlogin',
    component: () => import('@/views/wx'),
  },
  // 支付成功页面
  {
    path: '/paysuccess',
    name: 'paysuccess',
    component: () => import('@/views/paysuccess'),
  },
  //登录
  {
    path: '/login',
    component: LoginView,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/resetPassword',
    component: ResetPassword,
  },
  // {
  //   path: '/d3',
  //   component: (resolve) => require(['@/views/check_index/component/d3'], resolve),
  // },
  {
    path: '/message',
    component: (resolve) => require(['@/views/message_index'], resolve),
    redirect: '/message/messagelist',
    children: [
      {
        path: 'messagelist',
        component: (resolve) => require(['@/views/message_index/component/messagelist'], resolve),
      },
      {
        path: 'messagelistDetail',
        component: (resolve) => require(['@/views/message_index/component/messagelistDetail'], resolve),
      }
    ]
  },
  {
    path: '/orgindex',
    component: Orgview,
    redirect: '/orgindex/basicInformation',
    children: [
      {
        path: 'basicInformation',
        component: basicInformation,
      },
      {
        path: 'myorder',
        component: myorder,
      },
      {
        path: 'invoice',
        component: invoice,
      },
      {
        path: 'accountnumber',
        component: accountnumber,
      },
      {
        path: 'chosePay',
        component: chosePay,
      },
    ],
  },
  {
    path: '/index',
    component: CheckView,
    redirect: '/index/Home',
    children: [
      {
        path: 'groupcheck', //1
        component: (resolve) =>
          require(['@/views/check_index/component/GroupCheck'], resolve),
      },
      {
        path: 'groupcheckupload', //
        component: (resolve) =>
          require(['@/views/check_index/component/GroupcheckUpload'], resolve),
      },
      {
        path: 'checkreport',
        component: (resolve) =>
          require(['@/views/check_index/component/CheckReport'], resolve),
      },
      {
        path: 'reportlist',
        component: (resolve) =>
          require(['@/views/check_index/component/ReportList'], resolve),
      },
      {
        path: 'systemsettings',
        component: (resolve) =>
          require(['@/views/check_index/component/systemSetting'], resolve),
      },
      {
        path: 'checkhistory',
        component: (resolve) =>
          require(['@/views/check_index/component/checkhistory'], resolve),
      },
      {
        path: 'groupcheckfile',
        component: (resolve) =>
          require(['@/views/check_index/component/groupcheckfile'], resolve),
      },
      {
        path: 'historyreportList',
        component: (resolve) =>
          require(['@/views/check_index/component/historyreportList'], resolve),
      },
      {
        path: 'packageview',
        component: (resolve) =>
          require(['@/views/check_index/component/package'], resolve),
      },
      {
        path: 'purchaserecord',
        component: (resolve) =>
          require(['@/views/check_index/component/purchaserecord'], resolve),
      },
      {
        path: 'standVersion',
        component: (resolve) =>
          require(['@/views/check_index/component/standVersion'], resolve),
      },

      {
        path: 'userecord',
        component: (resolve) =>
          require(['@/views/check_index/component/userecord'], resolve),
      },
      {
        path: 'chosePay',
        component: (resolve) =>
          require(['@/component/orderpay/chosePay'], resolve),
      },
      {
        path: 'Home',
        component: (resolve) =>
          require(['@/views/check_index/component/Home'], resolve),
      },
    ],
  },
  // 投标公司信息检测报告详情
  {
    path: '/companydetail',
    component: (resolve) =>
      require(['@/views/check_index/component/companyDetail'], resolve),
  },
  {
    path: '/reportdetail',
    component: (resolve) =>
      require(['@/views/check_index/component/ReportDetail'], resolve),
  },
]

export default new VueRouter({
  routes,
})
