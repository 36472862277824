
<template>
  <div class="delmain" v-if="isshow">
    <div class="content">
      <div class="content-header">
        <div class="header-title">提示</div>
        <i class="el-icon-close" @click="isno"></i>
      </div>
      <div class="top">
        <div class="topicon">
          <i class="el-icon-info"></i>
        </div>
        <div class="infos">{{ content }}</div>
      </div>
      <div class="btns">
        <div class="btnno" @click="isno">取消</div>
        <div class="btnok" @click="isok">确定</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['show', 'content'],
  data () {
    return {
    }
  },
  methods: {
    isok () {
      this.$emit('handelsubmit', true)
    },
    isno () {
      this.$emit('close', false)
    },
  },
  computed: {
    isshow: function () {
      return this.show
    }
  }
}
</script>

<style lang="less" scoped>
.delmain {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: -56px;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;

  .content-header {
    height: 30px;
    line-height: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-icon-close {
      cursor: pointer;
    }

    .header-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #606266;
      line-height: 20px;

    }
  }

  .content {
    padding: 12px 14px 22px 29px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    .top {
      padding-right: 37px;
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 16px 0 35px 0;

      .topicon {
        i {
          font-size: 18px;
          color: #C5D0DE;
          line-height: 24px;
        }
      }

      .infos {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #606266;
        line-height: 24px;
        margin-left: 10px;
      }

    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 80px;
        height: 36px;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }

      .btnno {
        border: 1px solid #CED0DA;
        color: #006EFE;
      }

      .btnok {
        background: #006EFE;
        color: #FFFFFF;
        margin-left: 12px;
      }
    }
  }
}
</style> 