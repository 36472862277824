<template>
  <div>
    <div class="header">
      <div class="header-left">
        <img src="@/assets/img1/logowhite.png" alt="" />
      </div>
      <div class="flexr">
        <div class="Home" @click="Home">首页</div>

        <div
          ref="containerMessage"
          style="position: relative; margin: 0 26px 0 20px; cursor: pointer"
        >
          <i class="el-icon-message messageicon" @click="ShowmessageShow"></i>
          <div v-if="$store.state.messageNum" class="messageNumber">
            {{ $store.state.messageNum }}
          </div>
        </div>
        <div ref="container">
          <div class="flexc showuserbox" @click="isModalOpen = !isModalOpen">
            <img
              class="userheader"
              src="@/assets/img/checkimg/userheader.png"
              alt=""
            />
            <i class="el-icon-caret-bottom bottomto"></i>
          </div>
          <div class="header_menu" v-if="isModalOpen">
            <div class="header_menu_top flexl">
              <img
                class="userimg"
                src="@/assets/img/checkimg/userheader.png"
                alt=""
              />
              <div class="username">账号：{{ info.username }}</div>
            </div>
            <div
              class="header_menu_item flexl"
              @click="linkto('/orgindex/basicInformation')"
            >
              <i class="el-icon-user"></i>
              <span>用户中心</span>
            </div>
            <div class="header_menu_item flexl" @click="changepassword">
              <i class="el-icon-edit-outline"></i>
              <span>修改密码</span>
            </div>
            <div class="header_menu_item flexl" @click="linktologin('/login')">
              <i class="el-icon-switch-button"></i>
              <span>退出账号</span>
            </div>
          </div>
        </div>
      </div>
      <div class="popups" v-if="messageShow">
        <div ref="containerMessageInfo" class="message_box message_boxs">
          <div class="message_header flexsb">
            <div class="heacer_title">站内信</div>
            <div class="flexl">
              <div
                class="header_lookmore"
                @click="linktoMessageList('/message/messagelist')"
              >
                查看更多
              </div>
              <i class="el-icon-close" @click="messageShow = false"></i>
            </div>
          </div>
          <div class="message_content">
            <div class="message_search flexsb">
              <div>
                <el-select
                  style="width: 120px"
                  v-model="pageinfo.read"
                  placeholder="全部"
                  @change="changeRead"
                >
                  <el-option
                    v-for="item in readOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  style="width: 120px; margin-left: 10px"
                  v-model="pageinfo.type"
                  placeholder="全部"
                  @change="changeRead"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <el-button
                class="popUp"
                :disabled="totlenumber ? false : true"
                @click="setallRead"
                >全部已读</el-button
              >
              <!-- <div class="mainBtnWhite"  @click="setallRead" style="border: 1px solid #BDC0C5;padding:8.5px 15px">全部已读</div> -->
            </div>

            <div
              class="message_itembox"
              ref="personDom"
              @scroll="listenBottomOut"
            >
              <div
                class="message_item"
                v-for="item in tableData"
                :key="item.id"
                style="cursor: pointer"
                @click="lookDetail(item.id)"
              >
                <div class="flexl">
                  <div
                    :class="[item.read ? 'not-messageRound' : 'messageRound']"
                  ></div>
                  <div class="message_info">{{ item.title }}</div>
                </div>
                <div class="flexsb" style="margin: 10px 0 0 15px">
                  <div class="package_info">{{ item.type }}</div>
                  <div class="message_time">
                    {{ timeFormatter(item.dateCreated) }}
                  </div>
                </div>
              </div>
              <div class="nomore" v-show="showNoMore">没有更多了</div>
            </div>
          </div>
        </div>
      </div>
      <div class="message_box changeBox" v-if="changeShow">
        <div class="message_header flexsb">
          <div class="heacer_title">修改密码</div>
          <div class="flexl">
            <i class="el-icon-close" @click="changeShow = false"></i>
          </div>
        </div>
        <div class="message_content">
          <el-form
            :model="passwordruleForm"
            :rules="rules"
            ref="passwordruleForm"
            label-width="0"
            class="demo-ruleForm"
          >
            <div class="draw_formtitle">原密码：</div>
            <el-form-item label="" prop="oldPassword">
              <el-input
                v-model="passwordruleForm.oldPassword"
                type="password"
              ></el-input>
            </el-form-item>
            <div class="draw_formtitle">新密码：</div>
            <el-form-item label="" prop="newPassword">
              <el-input
                v-model="passwordruleForm.newPassword"
                type="password"
              ></el-input>
            </el-form-item>
            <div class="draw_formtitle">确认密码：</div>
            <el-form-item label="" prop="newreadyPassword">
              <el-input
                v-model="passwordruleForm.newreadyPassword"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="changeBtm flexl">
          <div class="mainBtn" style="margin: 0 10px 0 20px" @click="isok">
            确认
          </div>
          <div class="mainBtnWhite" @click="changeShow = false">取消</div>
        </div>
      </div>
    </div>
    <div class="dialogbox">
      <el-dialog
        title="修改密码"
        :visible.sync="dialogVisiblepassword"
        width="480px"
        center
      >
        <el-form
          :model="passwordruleForm"
          :rules="rules"
          ref="passwordruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="原密码：" prop="oldPassword">
            <el-input
              v-model="passwordruleForm.oldPassword"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPassword">
            <el-input
              v-model="passwordruleForm.newPassword"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="newreadyPassword">
            <el-input
              v-model="passwordruleForm.newreadyPassword"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btns">
          <div class="btnok" @click="isok">确定</div>
          <div class="btnno" @click="dialogVisiblepassword = false">取消</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { userInfo, usercChangepwd } from "@/api/saasuser.js";
import { getMessageList, allRead, getUnRead } from "@/api/org.js";

import { settoken } from "@/utils";
export default {
  data() {
    return {
      changeShow: false,
      messageShow: false,
      dialogVisiblepassword: false,
      ruleForm: {},
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^[\w_!@#$%&?._-]{6,16}$/,
            message: "请输入正确的密码",
            trigger: "blur",
          },
        ],
        newreadyPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            pattern: /^[\w_!@#$%&?._-]{6,16}$/,
            message: "请输入正确的密码",
            trigger: "blur",
          },
        ],
      },
      info: null,
      passwordruleForm: {},
      isModalOpen: false,
      readOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "true",
          label: "已读",
        },
        {
          value: "false",
          label: "未读",
        },
      ],
      typeOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "套餐消息",
          label: "套餐消息",
        },
        {
          value: "检测消息",
          label: "检测消息",
        },
        {
          value: "开票消息",
          label: "开票消息",
        },
        {
          value: "知也云动态",
          label: "知也云动态",
        },
      ],
      tableData: [],
      totlenumber: 3,
      pageinfo: {
        read: "",
        type: "",
        title: "",
        size: 15,
        pageNumber: 0,
      },
      showNoMore: false,
    };
  },
  watch: {
    $route(to, from) {
      this.messageShow = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.otherClose);
    window.removeEventListener("click", this.otherCloseMessage);
  },
  mounted() {
    window.addEventListener("click", this.otherClose);
    window.addEventListener("click", this.otherCloseMessage);
    this.getuserInfo();
    this.getList();
    this.getUnreads();
  },
  methods: {
    async setallRead() {
      let { data } = await allRead();
      if (data.code == 200) {
        this.$message({
          message: "成功标记为已读！",
          type: "success",
        });
        this.getUnreads();
        this.pageinfo.pageNumber = 0;
        this.tableData = [];
        this.getList();
      }
    },
    changeRead() {
      this.pageinfo.pageNumber = 0;
      this.tableData = [];
      this.getList();
    },
    ShowmessageShow() {
      this.messageShow = true;
      console.log(this.messageShow);
    },
    // 详情
    async lookDetail(id) {
      this.messageShow = false;
      this.$router.push(`/message/messagelistDetail?id=${id}`);
    },
    //触底触发函数
    listenBottomOut() {
      console.log("滚动了", this.pageinfo.pageNumber, this.totlenumber);
      this.$refs.personDom.addEventListener(
        "scroll",
        (res) => {
          let height = res.target;
          let clientHeight = height.clientHeight; //盒子的高度
          let scrollTop = height.scrollTop; //滚动的高度
          let scrollHeight = height.scrollHeight;
          if (scrollTop + clientHeight >= scrollHeight) {
            this.pageinfo.pageNumber = this.pageinfo.pageNumber + 1;
            if (
              !this.showNoMore &&
              this.pageinfo.pageNumber <= this.totalPages
            ) {
              this.getList();
            }
            return;
          }
        },
        true
      );
    },
    async getUnreads() {
      let { data } = await getUnRead();
      if (data.code == 200) {
        this.$store.commit("setMessageNum", data.data);
      }
    },
    // 站内信
    async getList() {
      let { data } = await getMessageList(this.pageinfo);
      if (
        data.content.length == 0 ||
        this.pageinfo.pageNumber > this.totalPages
      ) {
        this.showNoMore = true;
        this.pageinfo.pageNumber = this.totalPages;
        return;
      }
      let _data = data.content;
      this.tableData = this.tableData.concat(_data);
      this.totlenumber = data.totalElements;
      this.totalPages = data.totalPages;
    },
    linkto(path) {
      this.$router.push(path);
    },
    open() {
      this.isModalOpen = true;
    },
    async getuserInfo() {
      let { data } = await userInfo();
      this.info = data;
    },
    changepassword() {
      this.passwordruleForm = {};
      this.changeShow = true;
    },
    async isok() {
      this.$refs["passwordruleForm"].validate(async (valid) => {
        if (valid) {
          if (
            this.passwordruleForm.newreadyPassword ==
            this.passwordruleForm.newPassword
          ) {
            let { data } = await usercChangepwd(this.passwordruleForm);
            if (data.code == 200) {
              this.$notify({
                message: "密码修改成功,请重新登录",
                type: "success",
              });
              settoken("");
              this.$router.push("/login");
            } else {
              this.$message({
                message: data.msg || "修改密码失败",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: "两次密码不一致，请重新输入",
              type: "error",
            });
          }
        }
      });
    },
    Home() {
      this.$router.push("/index/Home");
    },
    otherClose(e) {
      if (this.isModalOpen) {
        if (this.$refs.container) {
          if (!this.$refs.container.contains(e.target)) {
            this.isModalOpen = false;
          }
        }
      }
    },
    otherCloseMessage(e) {
      if (this.messageShow) {
        if (this.$refs.containerMessageInfo) {
          if (
            !this.$refs.containerMessageInfo.contains(e.target) &&
            !this.$refs.containerMessage.contains(e.target)
          ) {
            this.messageShow = false;
          }
        }
      }
    },

    linktologin(path) {
      if (path == "/login") {
        settoken("");
      }
      this.$router.push(path);
    },
    linktoMessageList(path) {
      this.messageShow = false;
      this.$router.push(path);
    },
    linkto(path) {
      if (path != this.$route.path) {
        this.$router.push(path);
      }
      this.isModalOpen = false;
    },
    changepage(path) {
      this.$router.push("/checkindex" + path);
    },
    // 时间格式化
    timeFormatter(time) {
      if (time) {
        var zoneDate = new Date(time).toJSON();
        var date = new Date(+new Date(zoneDate) + 8 * 3600 * 1000)
          .toISOString()
          .replace(/T/g, " ")
          .replace(/\.[\d]{3}Z/, "");
        return date;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialogbox {
  /deep/.el-dialog {
    border-radius: 12px;
    overflow: hidden;
  }

  /deep/.el-dialog__header {
    height: 58px;
    background: #1990ff;
    border-radius: 12px 12px 0px 0px;
  }

  /deep/.el-dialog__title {
    font-size: 18px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500 !important;
    color: #ffffff !important;
    line-height: 58px !important;
  }

  /deep/.el-dialog__close {
    font-size: 18px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500 !important;
    color: #ffffff !important;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;

    div {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }

    .btnno {
      background: linear-gradient(360deg, #eaeef3 0%, #ffffff 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border: 1px solid #ced0da;
      color: #354052;
      margin-left: 12px;
    }

    .btnok {
      background: linear-gradient(360deg, #157bd1 0%, #2da1f8 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border: 1px solid #1585d8;
      color: #ffffff;
    }
  }
}

.header {
  height: 56px;
  background: #26303e;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 0 39px 0 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .header-left {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 28.5px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
    user-select: none;
  }

  .Home {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    cursor: pointer;
  }

  .messageNumber {
    position: absolute;
    top: -5px;
    right: -6px;
    min-width: 14px;
    height: 14px;
    background: #1990ff;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 14px;
    text-align: center;
    border-radius: 10px;
    // padding: 0 8px;
    padding: 0 5px;
  }

  .showuserbox {
    cursor: pointer;
  }

  .header_menu {
    width: 192px;
    min-height: 180px;
    background: #151822;
    position: absolute;
    right: 0;
    top: 56px;
    z-index: 1200;

    .header_menu_item:hover {
      background: #3a4555;
    }

    .header_menu_item {
      height: 34px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 34px;
      padding-left: 30px;
      margin: 8px 0;
      cursor: pointer;

      span {
        font-size: 12px;
      }

      i {
        margin-right: 15px;
      }
    }

    .header_menu_top {
      border-bottom: 1px solid #5a5c63;
      height: 48px;

      .userimg {
        height: 30px;
        border-radius: 100%;
        margin: 0 3px 0 10px;
      }

      .username {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
      }
    }
  }

  .messageicon {
    font-size: 20px;
    color: #fff;
    cursor: pointer;
  }

  .bottomto {
    font-size: 13px;
    color: #fff;
    margin-left: 7px;
  }

  .userheader {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  .el-icon-bell {
    font-size: 18px;
    margin: 0 18px;
    cursor: pointer;
  }

  .next {
    width: 10px;
    margin-left: 5px;
  }
}

.changeBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .message_header {
    width: 100%;
  }

  .message_content {
    width: 100%;
    flex: 1;

    .draw_formtitle {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 17px;
      margin-bottom: 13px;
    }
  }

  .changeBtm {
    width: 100%;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(176, 176, 176, 0.5);
  }
}

.message_box {
  width: 458px;
  height: calc(100vh - 56px);
  position: absolute;
  right: 0;
  top: 56px;
  z-index: 1200;

  .message_header {
    height: 48px;
    background: #006efe;
    padding: 0 21px;

    .heacer_title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
    }

    .header_lookmore {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      margin-right: 18px;
      cursor: pointer;
      user-select: none;
    }

    .el-icon-close {
      font-size: 22px;
      color: #fff;
      cursor: pointer;
    }
  }

  .message_content {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(161, 161, 161, 0.5);
    padding: 18px 20px;

    .nomore {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #74798c;
      line-height: 17px;
      text-align: center;
      margin: 15px 0;
    }

    .message_itembox {
      height: calc(100vh - 56px - 48px - 37px - 36px);
      padding-top: 18px;
      overflow: auto;

      .message_item {
        padding-top: 9px;
        height: 68px;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);

        .messageRound {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: #006efe;
        }
        .not-messageRound {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: #fff;
        }

        .message_info {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #74798c;
          line-height: 18px;
          margin-left: 5px;
          cursor: pointer;
          width: 98%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .package_info {
          width: 68px;
          height: 24px;
          background: #f3f4f8;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #74798c;
          line-height: 24px;
          text-align: center;
        }

        .message_time {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #74798c;
          line-height: 17px;
        }
      }
    }
  }
}

/deep/ .popUp {
  border-color: #006efe;
  color: #006efe;
}
.popups {
  position: absolute;
  right: 0;
  top: 56px;
  width: 100%;
  height: calc(100vh - 56px);
  z-index: 11;
  .message_boxs {
    width: 458px;
    top: 0;
    position: absolute;
    right: 0;
    z-index: 1200;
  }
}
/deep/ .el-button {
  padding: 0;
  height: 36px;
  padding: 0 15px;
}
</style>