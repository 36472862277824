import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Base64 from 'js-base64'
import '@/styles/index.scss' // 全局样式
import store from './store';
import http from './utils/http';

Vue.use(Base64)
Vue.use(ElementUI);
import moment from 'moment'
Vue.prototype.$moment = moment
Vue.config.productionTip = false
import * as filters from '@/filters'
// 全局过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
Vue.prototype.$http = http


new Vue({
  render: h => h(App),
  router, store
}).$mount('#app')
