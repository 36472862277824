<template>
  <div>
    <div class="content_header flexsb">
      <div class="view_name flexl">
        <i class="el-icon-arrow-left" v-if="backto" @click="back"></i>
        <span>{{ title }}</span>
      </div>
      <div class="help_center">
        <el-button type="text" icon="el-icon-video-play" @click="showMp4"
          >演示视频</el-button
        >
        <el-button type="text" icon="el-icon-document" @click="tohelpcenter"
          >帮助文档</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "backto", "helpUrl"],
  methods: {
    showMp4() {
      let url = `${window.location.href.split("/#/")[0]}/static/show.mp4`;
      window.open(url, "_blank");
    },
    back() {
      this.$emit("backTohistory");
    },
    tohelpcenter() {
      let url = "https://docs.kicheck.cc/";
      if (this.helpUrl) {
        window.open(`${url}${this.helpUrl}`);
      } else {
        window.open(`${url}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content_header {
  height: 56px;
  background: #ffffff;
  padding: 0 40px 0 24px;

  .view_name {
    .el-icon-arrow-left {
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      cursor: pointer;
      margin-right: 10px;
    }

    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
    }
  }

  .help_center {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #006efe;
    line-height: 17px;
    cursor: pointer;
  }
}
</style> 