<template>
  <div class="main">
    <div class="searchBox flexl">
      <el-input
        v-model="tableRequest.code"
        style="width: 220px"
        placeholder="请输入发票订单编号"
      ></el-input>
      <div style="margin: 0 10px">
        <el-date-picker
          v-model="searchTime"
          type="daterange"
          start-placeholder="开始日期"
          @change="changetime"
          value-format="yyyy-MM-dd"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-select
        style="width: 160px; margin-right: 10px"
        v-model="dataInvoiceType"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="mainBtn" @click="searchList">
        <i class="el-icon-search"></i> 搜索
      </div>
      <div style="margin-left: 10px" class="mainBtnWhite" @click="reset">
        <i class="el-icon-refresh-left"></i> 重置
      </div>
    </div>
    <div class="tableBox">
      <el-table
        class="elTable"
        height="calc(100vh - 280px)"
        :header-cell-style="headerCellStyle"
        :row-style="{ height: '56px' }"
        :data="tableData"
      >
        <el-table-column
          align="center"
          prop="code"
          label="发票订单编号"
          width="320"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="dateCreated"
          label="申请时间"
          width="150"
        >
          <template slot-scope="scope">
            {{ $moment(scope.row.dateCreated).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="invoiceHeader"
          label="发票抬头"
        ></el-table-column>
        <el-table-column align="center" prop="invoiceType" label="发票类型">
          <!-- <template slot-scope="scope">
            {{ scope.row.invoiceType == 0 ? '电子普通发票' : '电子增值税专用发票' }}
          </template> -->
          <template slot-scope="scope">
            <span v-if="scope.row.openType == 0" style="font-size: 12px">
              {{
                scope.row.invoiceType == 0
                  ? "电子普通发票(公司)"
                  : "电子增值税专用发票"
              }}
            </span>
            <span v-else style="font-size: 12px">电子普通发票(个人)</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="totalAmount"
          label="发票金额（元）"
        >
          <template slot-scope="scope"> ¥{{ scope.row.totalAmount }} </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="开票状态">
          <template slot-scope="scope">
            <div style="font-size: 12px" v-if="scope.row.status == 0">
              开票中
            </div>
            <div style="font-size: 12px" v-if="scope.row.status == 1">
              已开票
            </div>
            <div style="font-size: 12px" v-if="scope.row.status == 2">
              已取消
            </div>
            <div style="font-size: 12px" v-if="scope.row.status == 3">
              审核拒绝
              <el-tooltip
                class="i-tips"
                effect="light"
                placement="bottom"
                :content="scope.row.failReason"
              >
                <span>
                  <i class="el-icon-warning" style="font-size: 13px"></i>
                </span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <div class="operatingbtnBox">
              <div class="operatingbtn" @click="look(scope.row)">查看</div>
              <div
                class="operatingbtn"
                @click="down(scope.row)"
                v-if="scope.row.status == 1"
              >
                <!-- && scope.row.invoiceType == 0 -->
                下载
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="tableRequest.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="发票信息"
      :visible.sync="dialogVisible"
      center
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="order-info" style="background: rgba(25, 144, 255, 0.1)">
        <h4 style="font-size: 14px">发票信息</h4>
        <p
          v-for="(voice, v_title) in voiceInfo"
          style="overflow: auto; font-size: 12px"
          :key="v_title"
        >
          {{ v_title }}:
          <span
            style="
              font-size: 12px;
              float: right;
              word-wrap: break-word;
              max-width: 400px;
            "
            >{{ voice }}</span
          >
        </p>
      </div>
      <div
        style="margin-top: 30px; background: rgba(224, 224, 224, 0.34)"
        class="order-info"
      >
        <h4 style="font-size: 14px">地址信息</h4>
        <p
          v-for="(address, title) in addressInfo"
          style="overflow: auto; font-size: 12px"
          :key="title"
        >
          {{ title }}:
          <span
            style="
              font-size: 12px;
              float: right;
              word-wrap: break-word;
              max-width: 400px;
            "
            >{{ address }}</span
          >
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { orderinvoiceList, invoiceDownload } from "@/api/org.js";
export default {
  data() {
    return {
      searchTime: "",
      value2: "",
      voiceInfo: {},
      addressInfo: {},
      dialogVisible: false,
      headerCellStyle: {
        height: "56px",
        background: " #D1E9FF",
        fontSize: "12px",
        color: " #000000",
      },
      tableRequest: {
        pageNumber: 0,
        size: 10,
        status: 2,
        invoice: true,
        invoiceType: "",
      },
      dataInvoiceType: "",
      tableData: [],
      total: 0,
      currentPage: 1,
      options: [
        {
          label: "增值税专业发票",
          value: "1",
        },
        {
          label: "电子普通发票（公司）",
          value: "0",
        },
        {
          label: "电子普通发票（个人）",
          value: "3",
        },
      ],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    changetime(val) {
      if (val) {
        this.tableRequest.startDate = val[0];
        this.tableRequest.endDate = val[1];
      } else {
        this.tableRequest.startDate = "";
        this.tableRequest.endDate = "";
      }
    },
    handleSizeChange(val) {
      this.tableRequest.size = val;
      this.tableRequest.pageNumber = 0;
      this.getlist();
    },
    searchList() {
      this.tableRequest.pageNumber = 0;
      this.getlist();
      this.currentpage = 1;
    },
    reset() {
      delete this.tableRequest.startDate;
      delete this.tableRequest.invoiceType;
      delete this.tableRequest.endDate;
      delete this.tableRequest.openType;
      (this.tableRequest = {
        pageNumber: 0,
        size: 10,
        status: 2,
        invoice: true,
        code: "",
      }),
        (this.dataInvoiceType = "");
      this.searchTime = "";
      this.tableRequest.pageNumber = 0;
      this.getlist();
    },
    handleCurrentChange(val) {
      this.tableRequest.pageNumber = val - 1;
      this.getlist();
      this.currentPage = val;
    },
    async down(row) {
      let name = row.url.split(row.code + "/")[1];

      let { data } = await invoiceDownload(row.code);
      console.log(data);
      const $link = document.createElement("a");
      $link.href = URL.createObjectURL(data);
      $link.download = name;
      $link.click();
      document.body.appendChild($link);
      document.body.removeChild($link); // 下载完成移除元素
      window.URL.revokeObjectURL($link.href); // 释放掉blob对象
      this.downLoading = false;
    },
    look(row) {
      this.voiceInfo = {
        发票订单编号: row.code,
        发票抬头: row.invoiceHeader,
        开具类型: row.openType == 1 ? "个人" : "企业",
        发票类型: row.invoiceType == 0 ? "电子普通发票" : "电子增值税专用发票",
        税务登记证号: row.taxpayerId,
        基本开户银行: row.bank,
        基本开户账号: row.bankAccount,
        注册地址: row.address,
        注册电话: row.phone,
        申请时间: this.$moment(row.dateCreated).format("YYYY-MM-DD"),
        "发票总额（元）": row.totalAmount,
        邮箱: row.email,
        备注: row.remark,
      };

      // console.log()
      this.addressInfo = {
        收件人姓名: row.name,
        收件地址: row.province + row.city + row.county + row.fullAddress,
        联系电话: row.phone,
      };
      this.dialogVisible = true;
    },
    async getlist() {
      if (this.tableRequest.openType) {
        delete this.tableRequest.openType;
      }
      if (this.dataInvoiceType == 1) {
        this.tableRequest.invoiceType = this.dataInvoiceType;
      }

      if (this.dataInvoiceType != "" && this.dataInvoiceType == 0) {
        this.tableRequest.openType = 0;
        this.tableRequest.invoiceType = "0";
      }

      if (this.dataInvoiceType == 3) {
        this.tableRequest.openType = 1;
        this.tableRequest.invoiceType = "";
      }

      console.log(this.tableRequest, "this.tableRequest");
      let { data } = await orderinvoiceList(this.tableRequest);
      this.tableData = data.content;
      this.total = data.totalElements;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background: #fff;
  padding: 20px 19px;
  height: 100%;

  .tableBox {
    margin-top: 10px;
  }

  .pagebox {
    margin-top: 15px;
    text-align: right;
  }
}

.order-info {
  padding: 10px 22px;
  border-radius: 7px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    color: rgba(44, 45, 58, 1);
  }

  p {
    padding: 8px 0;
    color: #838f99;

    span {
      color: #2c2d3a;
    }
  }
}

/* el-table 宽度自适应 */
.elTable {
  width: 100%;
}

.elTable/deep/ .el-table__header-wrapper table,
.elTable/deep/ .el-table__body-wrapper table {
  width: 100% !important;
}

.elTable /deep/ .el-table__body,
.elTable /deep/ .el-table__footer,
.elTable /deep/ .el-table__header {
  table-layout: auto;
}

.elTable /deep/ .el-table__empty-block,
.elTable/deep/ .el-table__body {
  width: 100% !important;
}

/deep/.cell {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 16px;
}

/deep/.el-dialog__title {
  font-size: 14px !important;
}
</style>