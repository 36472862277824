<template>
  <div id="app">
    <router-view></router-view>
    <outlogin></outlogin>
  </div>
</template>

<script>
import outlogin from "@/component/outlogin.vue"
export default {

  name: 'App',
  components: {
    outlogin
  },
}
</script>
<style lang="less">
.flexsa {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flexr {
  display: flex;
  align-items: center;
  justify-content: right;
}

.flexl {
  display: flex;
  align-items: center;
  justify-content: left;
}

.flexsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexlnc {
  display: flex;
  justify-content: left;
}

* {
  margin: 0;
  padding: 0;
}

// ::-webkit-scrollbar {
//     display: none;
// }
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

.prism-big-play-btn {
  position: absolute;
  left: 123px !important;
  bottom: 60px !important;
}

#app {
  background-color: #fff;
}

//全局查看大图关闭图标
.el-image {
  .el-image-viewer__wrapper {
    .el-icon-circle-close {
      font-size: 40px;
      color: #fff;
    }
  }
}
</style>
