<template>
  <div class="chooseindex">
    <contenttitle title="确认支付" :backto="true"  :helpUrl="`saas-ban/cha-zhong-jian-ce/wo-de-tao-can/wo-de-tao-can`" @backTohistory="backTohistory"></contenttitle>

    <div class="chooseMain">
      <div  class="chooseContent">
        <div  style="margin:15px 0 56px; " v-if="$store.state.userType!=2 && editInfo">
        <!-- <div  style="margin:15px 0 56px; " > -->
          <div  class="commonTitle flex-between">
          <div class="flex-align-cener">
            <img src="@/assets/img1/icon/leftto.png" alt="">
          <div class="buytitle_name">企业详情</div>
          </div>
          <div v-if="showState == 0" style="cursor: pointer;"  @click="linkto('/orgindex/myorder')">
              <i class="el-icon-s-order"></i>
              订单管理
          </div>
        </div>
        <div class="useritem" >
            <div class="useritem-title">企业名称：</div>
            <div class="flexsb" v-if="!orgNameActive" style="height:28px;">
              <span class="useritem_info">
                {{ usersInfo.orgName?usersInfo.orgName:'请输入企业名称' }}
              </span>
              <i v-if="showState == 0" class="el-icon-edit-outline" style="color:#5E94FF;font-size:18px;margin-left:8px;cursor: pointer;" @click="orgNameActive = true"></i>
            </div>
            <div  v-else class="flexsb changephone"  >
              <el-input size="mini" style="width: 200px;font-size:14px;" v-model="usersInfo.orgName" placeholder="请输入内容"></el-input>
              <div class="flexl">
                <i class="el-icon-close" style="color:#EE4D4D;font-size:18px;margin:0 8px;" @click="orgNameActiveChange"></i>
                <i class="el-icon-check" style="color:#8BC94D; font-size:18px;" @click="orgNameActiveChange"></i>
              </div>
            </div>
          </div>

          <div class="useritem" >
            <div class="useritem-title">联系方式：</div>
            <div class="flexsb" v-if="!mobileActive" style="height:28px;">
              <span class="useritem_info">
                {{ usersInfo.mobile?usersInfo.mobile:'请输入联系方式' }}
              </span>
              <i v-if="showState == 0" class="el-icon-edit-outline" style="color:#5E94FF;font-size:18px;margin-left:8px;" @click="mobileActive = true"></i>
            </div>
            <div v-else class="flexsb changephone">
              <el-input size="mini" style="width: 200px; font-size:14px;" v-model="usersInfo.mobile" placeholder="请输入内容"></el-input>
              <div class="flexl">
                <i class="el-icon-close" style="color:#EE4D4D;font-size:18px;margin:0 8px;" @click="mobileActiveChange"></i>
                <i class="el-icon-check" style="color:#8BC94D;font-size:18px;" @click="mobileActiveChange"></i>
              </div>
            </div>
          </div>
        </div>

      <div v-if="showState == 0">
        <div class=" commonTitle flex">
         <div  class="  flex">
          <img src="@/assets/img1/icon/leftto.png" alt="">
          <div class="buytitle_name">订单详情</div>
         </div>
         <!-- <div style="cursor: pointer;"  @click="linkto('/orgindex/myorder')">
            <i class="el-icon-s-order"></i>
            订单管理
         </div> -->
        </div>
        <div class="infos">
          <div>收款方：彦旭科技（武汉）有限公司</div>
          <div>订单号：{{ this.$route.query.orderNum }}</div>
          <div>
            <div class="flex">
              <div>
                商品名称：{{ this.$route.query.name }}
              </div>
            </div>
          </div>
          <div>支付金额：<span>￥{{ this.$route.query.price }}</span></div>
        </div>
        <div class="commonTitle" style="margin-top: 56px;">
          <img src="@/assets/img1/icon/leftto.png" alt="">
          <div class="buytitle_name">请选择支付方式</div>
        </div>
        <div class="price-container">
          <div class="item-container">
            <div @click="nowActive = index"
              :class="[nowActive == index ? $route.query.smsnum ? 'active2' : 'active' : '']"
              v-for="( item, index ) in  payoption " :key="index">
              <img :src="imgList[index]" alt="" />
              <div class="payName">
                <span>{{ item.name }}</span>
                <div class="payinfo">{{ item.memo }}</div>
              </div>

            </div>
          </div>
          <div class="mainBtn" @click="handlePay" style="width: 120px;margin-top:82px;">
            立即支付
          </div>
        </div>
      </div>
      <div v-else-if="showState == 2">
        <bankPay :frompath='fullPath'></bankPay>
      </div>
      <div v-else>
        <wechatPay :frompath='fullPath'> </wechatPay>
      </div>
    </div>
    </div>
    <!-- v-if="showState == 0" -->
    <!-- <div v-else-if="showState == 2">
      <div>
        <bankPay :frompath='fullPath'></bankPay>
      </div>
    </div>
    <div v-else>
      <wechatPay :frompath='fullPath'> </wechatPay>
    </div> -->
  </div>
</template>

<script>
import bankPay from './bankPay'
import wechatPay from './wechatPay'
import ali from '@/assets/img1/alipay.png'
import wepay from '@/assets/img1/wepay.png'
import bankpay from '@/assets/img1/bankpay.png'
import contenttitle from "@/component/contentTitle"
import { userInfo } from '@/api/saasuser'
import {  updatemobileororgName } from "@/api/org.js"
import { pointsInfo } from '@/api/org.js'

export default {
  name: 'index',
  components: {
    bankPay,
    wechatPay,
    contenttitle
  },
  data () {
    return {
      returnLink:true,
      mobileActive: false,
      orgNameActive:false,
      editInfo:false,
      usersInfo: {},
      nowActive: 0,
      showState: 0,
      imgList: [ali, wepay, bankpay],
      frompath: '',
      pointsInfo: {},
      payoption: [
        { name: '支付宝 扫码支付', memo: '使用支付宝支付' },
        { name: '微信 扫码支付', memo: '使用微信支付' },
        { name: '银行 转账支付', memo: '使用银行转账支付' },
      ]
    }
  },
  mounted(){
    this.getuserType();
    this.getUserinfo();
    if(this.$route.query.name.indexOf('月卡')!=-1||this.$route.query.name.indexOf('季卡')!=-1||this.$route.query.name.indexOf('年卡')!=-1||this.$route.query.name.indexOf('升级为企业用户')!=-1){
      this.editInfo=true;
      console.log(11111)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fullPath = from.path;
    });
  },
  methods: {
    async getuserType () {
      let  {data}  = await pointsInfo()
      this.pointsInfo = data
      this.$store.commit('getUsertype', data.userType)

    },
   async getUserinfo() {
    let { data } = await userInfo()
    this.usersInfo=data;
    },
    mobileActiveChange() {
      this.mobileActive=false;
    },
    orgNameActiveChange() {
      this.orgNameActive=false;
    },
    async changeName() {
      let form = {
        id: this.usersInfo.id,
        name: this.usersInfo.orgName
      }
      let { data } = await updatemobileororgName(form)
      if (data.code == 200) {
        if(!this.usersInfo.mobile){
          this.$message({
              message: '请输入联系方式！',
              type: 'error',
            });
            return;
        }else{
          this.changeMobile();
        }
        this.orgNameActive = false
      } else {
        this.$message({
          message: data.msg || '企业名称修改失败',
          type: 'error',
        })
      }

    },
    async changeMobile() {
      var reg = '^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|(147))\\d{8}$';
      var regExp = new RegExp(reg);
      if (regExp.test(this.usersInfo.mobile)) {
        let form = { id: this.usersInfo.id, mobile: this.usersInfo.mobile }
        let { data } = await updatemobileororgName(form)
        if (data.code == 200) {
          this.mobileActive = false
          switch (this.nowActive) {
            case 0:
              let url = this.$http.defaults.baseURL + `/bid-check-service/open/alipay/${this.$route.query.orderNum}`
              window.open(url)
              break
            case 1:
              this.showState = 1
              break
            case 2:
              this.showState = 2
              break
          }
        }else{
          this.$message({
            message: data.msg || '手机号修改失败',
            type: 'error',
          })
        }
      } else {
        this.$message({
          message: '请输入正确的手机号',
          type: 'error',
        });
      }
     
    },
    backTohistory () {
      if(this.$route.query.name.indexOf('升级为企业用户')!=-1){
        this.$router.push('/orgindex/basicInformation');
        return;
      }
      if (this.showState != 0) {
        this.showState = 0
      } else {
        this.$router.push(this.$route.query.pathaddress)
      }
    },
    backto () {
      if (this.showState != 0) {
        this.showState = 0
      } else {
        this.$router.push(this.fullPath)
      }
    },
    linkto(path) {
      this.$router.push(path)
    },
    handlePay () {
      console.log('handlePay')
      let val = this.nowActive
      if(this.$store.state.userType!=2 && this.editInfo){
        if(!this.usersInfo.orgName){
          this.$message({
              message: '请输入企业名称！',
              type: 'error',
            });
            return;
        }else{
         this.changeName(); 
        }
      }else{
        switch (val) {
          case 0:
            let url = this.$http.defaults.baseURL + `/bid-check-service/open/alipay/${this.$route.query.orderNum}`
            window.open(url)
            break
          case 1:
            this.showState = 1
            break
          case 2:
            this.showState = 2
            break
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.chooseMain {
  min-height: calc(100vh - 112px);
  background: #F3F4F8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .chooseContent {
    width: 100%;
    flex: 1;
    background: #Fff;
    padding: 20px;

    .infos {
      margin: 16px 0 0 16px;

      div {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 32px;

        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 22px;
        }
      }
    }

    .price-container {
      margin-top: 18px;

      .active {
        border: 1px solid #006EFE;
      }



      >div {
        display: flex;

        >div {
          width: 224px;
          height: 56px;
          padding: 8px;
          margin-right: 24px;
          border-radius: 4px;
          border: 1px solid #E0EAF1;
          cursor: pointer;

        }

      }

      .item-container {
        >div {
          display: flex;
          align-items: center;
          justify-content: left;


          span {
            font-size: 14px !important;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2D3A;
            line-height: 14px;
          }

          .payName {
            margin-left: 8px;

            .payinfo {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #838F99;
              line-height: 11px;
              margin-top: 2px;
            }
          }



          img {
            width: 40px;
            height: 40px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}





.login-btn {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  margin-top: 13px;
  color: #fff;
  border-color: #fff;
  font-size: 16px !important;
}

.chooseindex {
  width: 100%;

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2c2d3a;
    line-height: 28px;
  }



  .price {
    padding: 16px 5px;
    font-size: 32px;
    font-weight: 600;
    color: rgba(44, 45, 58, 1);
  }

}

.infosize {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  line-height: 32px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: left;
}

.backbox {
  height: 57px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 18px;

  .back {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2c2d3a;
    line-height: 57px;
    padding: 0 15px;
    cursor: pointer;
  }
}

.infocontent {
  background: #fff;
  padding: 15px 18px 30px 18px;
  border-radius: 8px;
  min-height: calc(100vh - 191px);
}
.flex{
  display: flex;
  justify-content: space-between;
}


.useritem{
  display:flex;
  margin: 8px 0 0 20px;
  align-items: center;
}
.useritem-title{
    font-size:14px;
  }
  .flex-align-cener{
    display: flex;
    align-items: center;
  }
  .flex-between{
    justify-content: space-between;
  }
</style>
