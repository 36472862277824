
// 格式化时间 时间戳
export function  timeFormatter (time) {
  if(!time)return ''
  var zoneDate = new Date(time).toJSON()
  var date = new Date(+new Date(zoneDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
  return date.substring(0,10)
}

// 格式化带T的时间
export function timeFormatDate(date) {
  if (!date) { return '';}
  var dateString = new Date(date);
  function addZero(time) {return time < 10 ? `0${time}` : time; }
  let FormattedDateTime;
    FormattedDateTime = `${dateString.getFullYear()}-${
      addZero(dateString.getMonth() + 1)
    }-${addZero(dateString.getDate())}`;
  return FormattedDateTime;
}


export function timeFormatterSFM (time) {
  var zoneDate = new Date(time).toJSON()
  var date = new Date(+new Date(zoneDate) + 8 * 3600 * 1000)
    .toISOString()
    .replace(/T/g, ' ')
    .replace(/\.[\d]{3}Z/, '')
  return date
}

