<template>
  <div>
    <div class="draw_wrap" v-if="drawShow">
      <div class="draw_left" @click="drawcancel"></div>
      <div class="draw" :style="`width:${width ? width : '458px'}`">
        <div class="draw_title flexsb">
          <div>{{ drawtitle }}</div>
          <i class="el-icon-close" @click="drawcancel"></i>
        </div>
        <div class="draw_content">
          <slot></slot>
        </div>
        <div class="draw_btn flexl" v-if="footerShow !== false">
          <div class="draw_btnok" v-if="okName" @click="drawconfirm">
            {{ okName }}
          </div>
          <div class="draw_btnok" @click="drawconfirm" v-else>确认</div>
          <div class="draw_btnno" @click="drawcancel">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["drawShow", "drawtitle", "footerShow", "okName", "width"],
  methods: {
    drawconfirm() {
      this.$emit("drawconfirm", this.drawtitle);
    },
    drawcancel() {
      this.$emit("drawcancel");
    },
  },
};
</script>

<style lang="less" scoped>
.draw_wrap {
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  align-items: center;
  justify-content: left;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1100;

  .draw_left {
    height: calc(100vh - 56px);
    flex: 1;
    opacity: 0;
    background: #fff;
  }

  .draw {
    height: calc(100vh - 56px);
    box-shadow: 0px 2px 8px 0px rgba(161, 161, 161, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    right: 0;

    .draw_title {
      width: 100%;
      height: 48px !important;
      background: #006efe;
      padding: 0 22px;

      div {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
      }

      i {
        color: #ffffff;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .draw_content {
      width: 100%;
      background: #fff;
      flex: 1;
    }

    .draw_btn {
      width: 100%;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(176, 176, 176, 0.5);

      div {
        width: 80px;
        height: 36px;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }

      .draw_btnno {
        background: linear-gradient(360deg, #ffffff 0%, #ffffff 100%);
        border: 1px solid #ced0da;
        color: #006efe;
      }

      .draw_btnok {
        background: #006efe;
        color: #ffffff;
        margin: 0 10px 0 20px;
      }
    }
  }
}
</style>