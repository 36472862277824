<template>
  <!-- 侧边导航 -->
  <div class="right-nav">
    <div class="nav-item tophone">
      <img src="@/assets/img2/客服咨询绿.png" alt="" />
      <div class="reddot"></div>
      <div class="tophonebox">
        <div class="phone-top">
          <div class="phoneImgbox">
            <img src="@/assets/img2/phone.png" alt="" />
          </div>
          <div class="timeBox">客户经理热线</div>
        </div>
        <div class="phonenumberTxt">400-086-1786</div>
        <div class="phone-top" style="margin-top: 7px">
          <div class="phoneImgbox">
            <img src="@/assets/img2/time-circle.png" alt="" />
          </div>
          <div class="timeBox">工作时间</div>
        </div>
        <div class="phonenumberTxt">工作日：9:00-19:00</div>
        <div class="custerSline"></div>
        <div class="custerSinfo">
          非工作时间欢迎添加微信/企业微信在线留言，待客户经理上线后将第一时间为您处理
        </div>
      </div>
    </div>
    <div class="nav-item tovx">
      <img src="@/assets/img2/微信.png" alt="" />
      <div class="vxbox">
        <img src="@/assets/img2/code1.jpg" alt="" />
        <p>微信扫码一对一沟通</p>
      </div>
    </div>
    <div class="nav-item tovx">
      <img src="@/assets/img2/workvx.png" alt="" />
      <div class="vxbox">
        <img src="@/assets/img2/code2.png" alt="" />
        <p>企业微信扫码一对一沟通</p>
      </div>
    </div>
    <a
      target="_blank"
      href="http://wpa.qq.com/msgrd?v=3&uin=3179561760&site=qq&menu=yes"
      class="qqa"
    >
      <div class="nav-item toqq">
        <img src="@/assets/img2/icon_QQ.png" alt="" />
        <div class="qqbox">
          <p>在线咨询</p>
          <p>点击立即咨询客户经理</p>
        </div>
      </div>
    </a>
    <div class="nav-item totop" @click="totop">
      <img src="@/assets/img2/返回顶部.png" alt="" />
    </div>
  </div>
  <!-- 侧边导航 -->
</template>

<script>
export default {
  methods: {
    totop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.right-nav {
  width: 70px;
  height: 280px;
  position: fixed;
  right: 0;
  top: 40%;
  background-color: #fff;
  z-index: 4;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
}

.nav-item {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #377cfd;
  border-bottom: 1px solid rgba(204, 204, 204, 0.35);
}

.nav-item:hover {
  background: #7ba5f4;
  cursor: pointer;
}

.nav-item img {
  height: 30px;
}

.tophone {
  position: relative;
}

.reddot {
  width: 8px;
  height: 8px;
  background: #ff0000;
  border-radius: 100%;
  position: absolute;
  top: 13px;
  right: 13px;
}

.tophone:hover .tophonebox {
  display: block;
}

.tophonebox p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}

.tophonebox p:nth-child(1) {
  margin: 12px 0 6px 0;
}

.tophonebox {
  padding: 10px 11px 0 11px;
  width: 168px;
  height: 160px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  left: -190px;
  top: 0;
  overflow: hidden;
  display: none;
}

.phoneImgbox {
  width: 12px;
  height: 12px;
  background: #3bc491;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.phoneImgbox > img {
  height: 10px;
  height: 10px;
}

.phone-top {
  display: flex;
  align-items: center;
  justify-content: left;
}

.timeBox {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 17px;
}

.phonenumberTxt {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
  margin: 6px 0 0 14px;
}

.custerSline {
  border: 1px dashed rgba(59, 196, 146, 0.5);
  margin: 7px 0;
}

.custerSinfo {
  padding: 0 3px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
}

.tovx {
  position: relative;
}

.tovx:hover .vxbox {
  display: block;
}

.vxbox {
  width: 140px;
  height: 140px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  display: none;
  left: -140px;
  top: 0;
}

.vxbox img {
  display: block;
  width: 112px;
  height: 112px;
  margin: 0 auto;
  margin-top: 7px;
}

.vxbox p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
  text-align: center;
}

.qqa {
  display: block;
}

.toqq {
  position: relative;
}

.toqq:hover .qqbox {
  display: block;
}

.qqbox {
  width: 140px;
  height: 70px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  left: -159px;
  top: 0;
  padding-left: 19px;
  display: none;
}

.qqbox p:nth-child(1) {
  margin: 12px 0 6px 0;
}

.qqbox p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
</style>