import request from '@/utils/http.js'

// 分页获取分组列表
export const groupPage = (params) => {
  return request({
    url: `/bid-check-service/client/group/page`,
    method: 'get',
    params
  })
}


// 创建分组
export const createGroup = (data) => {
  return request({
    url: `/bid-check-service/client/group/create`,
    method: 'post',
    data
  })
}

// 删除分组
export const groupDelete = (id) => {
  return request({
    url: `/bid-check-service/client/group/${id}/delete`,
    method: 'delete',
  })
}

// 更新分组
export const groupUpdate = (data) => {
  return request({
    url: `/bid-check-service/client/group/update`,
    method: 'put',
    data
  })
}

// 上传文档
export const groupUpload = (id, data) => {
  return request({
    url: `/doc/${id}/upload`,
    method: 'post',
    data
  })
}

// 获取文档列表
export const groupUploadlist = (id) => {
  return request({
    url: `/bid-check-service/client/doc/${id}/list`,
    method: 'get',
  })
}

// 删除文档
export const delgroupUploadfile = (gid, id) => {
  return request({
    url: `/bid-check-service/client/doc/${gid}/delete/${id}`,
    method: 'post',
  })
}

// 开始查重
export const docCheck = (gid, params) => {
  return request({
    url: `/bid-check-service/client/${gid}/check`,
    method: 'post',
    params
  })
}

// 获取个人系统设置
export const getSetting = (gid) => {
  return request({
    url: `/bid-check-service/client/user/settings`,
    method: 'get',
  })
}

// 获取系统设置
export const getSettings = (gid) => {
  return request({
    url: `/bid-check-service/settings/settings`,
    method: 'get',
  })
}


// 保存系统设置
export const saveSetting = (data) => {
  return request({
    url: `/bid-check-service/client/user/settings/save`,
    method: 'post',
    data
  })
}

// 报告列表
export const reportPage = (params) => {
  return request({
    url: `/bid-check-service/client/report/page`,
    method: 'get',
    params
  })
}

// 获取报告详情
export const reportDetail = (rid) => {
  return request({
    url: `/report/doc/${rid}`,
    method: 'get',
  })
}

// 获取文档查重详情
export const reportdocDetail = (cid) => {
  return request({
    url: `/bid-check-service/client/report/detail/${cid}`,
    method: 'get',
  })
}

// 删除报告
export const deleteReport = (data) => {
  return request({
    url: `/bid-check-service/client/report/delete`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 获取文档属性查重表格
export const reportpropertyTable = (rid) => {
  return request({
    url: `/bid-check-service/client/report/${rid}/property/table`,
    method: 'get',
  })
}


// 获取投标公司关联性检测报告
export const relationTable = (rid) => {
  return request({
    url: `/bid-check-service/client/report/${rid}/relation`,
    method: 'get',
  })
}


// 获取文档查重表格
export const reportTable = (rid) => {
  return request({
    url: `/bid-check-service/client/report/${rid}/table`,
    method: 'get',
  })
}

// 获取比对文档详情
export const reportDetailInfo = (cid) => {
  return request({
    url: `/bid-check-service/client/report/check/${cid}`,
    method: 'get',
  })
}
// 获取文档属性查重详情
export const propertyDetailInfo = (cid) => {
  return request({
    url: `/report/property/${cid}`,
    method: 'get',
  })
}

// 获取分组
export const groupId = (id) => {
  return request({
    url: `/group/${id}/get`,
    method: 'get',
  })
}

// 下载文档
export const downloadFile = (id) => {
  // return request({
  //   url: `/bid-check-service/client/doc/${id}/download`,
  //   method: 'post',
  //   responseType: "blob"
  // })
  return request({
    url: `/bid-check-service/client/doc/${id}/download`,
    method: 'post',
    responseType: "blob"
  })
}


// 获取白名单文档
export const getWhiteFile = (gid) => {
  return request({
    url: `/bid-check-service/client/doc/${gid}/white`,
    method: 'post',
  })
}

//导出报告
export const exportReport = (rid, params) => {
  return request({
    url: `/report/export/${rid}`,
    method: 'get',
    responseType: "blob",
    params
  })
}

//showDoc
export const docShow = (id) => {
  return request({
    url: `/open/${id}/show`,
    method: 'get',
    // responseType: "blob"
  })
}

// 获取文档属性查重表格
export const propertyReport = (id) => {
  return request({
    url: `/bid-check-service/client/report/${id}/property`,
    method: 'get',
    // responseType: "blob"
  })
}

//获取报告全部详情
export const getAlldetail = (id) => {
  return request({
    url: `/bid-check-service/client/report/all/${id}`,
    method: 'get'
  })
}


// 显示文件
export const showdoc = (id) => {
  return request({
    url: `/bid-check-service/client/${id}/show`,
    method: 'get',
    responseType: "blob"
  })
}
// 导出报告详情
export const exportDetail = (cid) => {
  return request({
    url: `/bid-check-service/client/report/export/detail/${cid}`,
    method: 'get',
    responseType: "blob"
  })
}


// 生成报告
export const createReport = (rid) => {
  return request({
    url: `/report/generate/${rid}`,
    method: 'post',
  })
}


// 获取报告的人工审核相似度 
export const auditSimilarity = (cid) => {
  return request({
    url: `/report/${cid}/audit_similarity`,
    method: 'get',
  })
}


// 修改文档信息
export const updateDoc = (id, data) => {
  return request({
    url: `/bid-check-service/client/doc/${id}/update`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 获取文档公司名和手机号查重表格
export const getFactor = (rid) => {
  return request({
    url: `/bid-check-service/client/report/${rid}/factor`,
    method: 'get',
  })
}

// 获取文档公司名和手机号查重详情
export const getFactordetail = (params) => {
  return request({
    url: `/bid-check-service/client/report/factor`,
    method: 'get',
    params
  })
}

// 添加敏感词
export const sensitivecreate = (data) => {
  return request({
    url: `/sensitive/words/create`,
    method: 'post',
    data,
  })
}


// 获取敏感词
export const sensitiveget = (params) => {
  return request({
    url: `/sensitive/words/get`,
    method: 'get',
    params,
  })
}

// 获取敏感词列表
export const sensitivelist = (params) => {
  return request({
    url: `/sensitive/words/list`,
    method: 'get',
    params,
  })
}

// 修改敏感词
export const sensitiveupdate = (data) => {
  return request({
    url: `/sensitive/words/update`,
    method: 'post',
    data,
  })
}


// 删除敏感词
export const sensitivedel = (data) => {
  return request({
    url: `/sensitive/words/delete`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 获取脱敏检测上传文档列表
export const sensitivedoclist = (params) => {
  return request({
    url: `/sensitive/doc/list`,
    method: 'get',
    params,
  })
}


// 文档归档
export const sensitivearchive = (data) => {
  return request({
    url: `/sensitive/doc/archive`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 开始检测
export const sensitivecheckstart = (data) => {
  return request({
    url: `/sensitive/check/start`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 获取报告列表
export const sensitivereportlist = (params) => {
  return request({
    url: `/sensitive/report/list`,
    method: 'get',
    params,
  })
}

// 删除文档
export const sensitivedocdelete = (data) => {
  return request({
    url: `/sensitive/doc/delete`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 删除报告 
export const sensitivereportdelete = (data) => {
  return request({
    url: `/sensitive/report/delete`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 显示文件
export const sensitiveshow = (id) => {
  return request({
    url: `/sensitive/${id}/show`,
    method: 'get',
    responseType: "blob"
  })
}


//获取重复字
export const sensitivereportWord = (id) => {
  return request({
    url: `/sensitive/report/${id}/detail`,
    method: 'get',
  })
}

// 获取最新报告
export const sensitivereportlatest = (docId) => {
  return request({
    url: `/bid-check-service/client/sensitive/report/${docId}/latest`,
    method: 'get',
  })
}


// 下载文档
export const sensitivedown = (id) => {
  return request({
    url: `/sensitive/${id}/download`,
    method: 'post',
    responseType: "blob"
  })
}

// 文档归档
export const docarchive = (data) => {
  return request({
    url: `/bid-check-service/client/doc/archive`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 文档合并
export const docmerge = (data) => {
  return request({
    url: `/doc/doc/merge`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}


// 获取最新报告
export const reportlatest = (gid) => {
  return request({
    url: `/bid-check-service/client/report/${gid}/latest`,
    method: 'get',
  })
}

// 导出敏感词报告
export const sensitivereportexport = (rid, params) => {
  return request({
    url: `/sensitive/report/export/${rid}`,
    method: 'get',
    params,
    responseType: "blob"
  })
}


// 导出数据
export const exportdata = () => {
  return request({
    url: `/common/export`,
    method: 'get',
    responseType: "blob"
  })
}

// 导入数据
export const importdata = (data) => {
  return request({
    url: `/common/import`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}



// 购买公司名手机号结果
export const factorBuy = (rid) => {
  return request({
    url: `/bid-check-service/client/report/factor/${rid}/buy`,
    method: 'post',
  })
}

// 购买属性结果
export const propertyBuy = (rid) => {
  return request({
    url: `/bid-check-service/client/report/property/${rid}/buy`,
    method: 'post',
  })
}

// 获取属性和公司名手机号查重结果购买状态
export const reportfactorStatus = (rid) => {
  return request({
    url: `/bid-check-service/client/report/factor/${rid}/status`,
    method: 'get',
  })
}

// 购买定位功能
export const reportlocationbuy = (cid) => {
  return request({
    url: `/bid-check-service/client/report/location/${cid}/buy`,
    method: 'post',
  })
}

// 获取详情和定位购买状态
export const reportdetailstatus = (cid) => {
  return request({
    url: `/bid-check-service/client/report/detail/${cid}/status`,
    method: 'get',
  })
}

// 协议详情
export const helpDetailById = (params) => {
  return request({
    url: `/cms-service/open/helpDetailById`,
    method: 'get',
  })
}


// 购买报告检测详情  
export const reportdetailBuy = (cid) => {
  return request({
    url: `/bid-check-service/client/report/${cid}/buy`,
    method: 'post',
  })
}

// 获取文档详情
export const clientdocGet = (cid) => {
  return request({
    url: `/bid-check-service/client/doc/${cid}/get`,
    method: 'get',
  })
}


// 获取当前等待任务数量
export const taskWaiting = (cid) => {
  return request({
    url: `/bid-check-service/client/task/waiting`,
    method: 'get',
  })
}


// 获取文档列表
export const getManagementList = (params) => {
  return request({
    url: `/bid-check-service/client/management/list`,
    method: 'get',
    params
  })
}

// 下载列表
export const managementDown = (id) => {
  return request({
    url: `/bid-check-service/client/management/${id}/download`,
    method: 'post',
    responseType: "blob"
  })
}

// 违法失信表格
export const getcreditreport = (rid) => {
  return request({
    url: `/bid-check-service/client/report/${rid}/credit`,
    method: 'get',
  })
}


// 军采网详情
export const JCcheckDetail = (id) => {
  return request({
    url: `/bid-check-service/client/report/${id}/check/detail`,
    method: 'get',
  })
}



// 获取文档详细信息
export const getDocinfo = (id) => {
  return request({
    url: `/bid-check-service/client/doc/${id}/get`,
    method: 'get'
  })
}
