<template>
  <div class="indexs">
    <!-- <h3 class="buy-title">
      实际支付金额
      <span
        >订单提交成功，请尽快付款！订单号：{{
          this.$route.query.orderNum
        }}</span
      >
    </h3>
    <p class="price">￥{{ this.$route.query.total }}</p> -->
    <div class="commonTitle">
      <img src="@/assets/img1/icon/leftto.png" alt="" />
      <div class="buytitle_name">订单详情</div>
    </div>
    <div class="infos">
      <div>收款方：彦旭科技（武汉）有限公司</div>
      <div>订单号：{{ this.$route.query.orderNum }}</div>
      <div v-if="this.$route.query.name">
        <div class="flex">
          <div>商品名称：{{ this.$route.query.name }}</div>
        </div>
      </div>
      <div>
        支付金额：<span>￥{{ this.$route.query.price }}</span>
      </div>
    </div>
    <div class="info-container">
      <div class="info-img"></div>
      <div class="info-cotent">
        <h4>通过银行转账进行支付，并将支付成功的页面截图当做凭证进行上传</h4>
        <div class="info-cotent-detail">
          <div class="info-cotent-detail-dt">
            <p>户名</p>
            <p>支行</p>
            <p>卡号</p>
            <p>上传凭证</p>
          </div>
          <div class="info-cotent-detail-dd">
            <p>彦旭科技（武汉）有限公司</p>
            <p>招商银行光谷科技支行</p>
            <p>1279 1145 7610 801</p>
            <p style="height: 120px">
              <el-upload
                ref="upload1"
                action="#"
                :auto-upload="false"
                :on-change="handelChange"
                :show-file-list="false"
                accept="image/gif,image/jpeg,image/jpg,image/png"
              >
                <img v-if="imgBase64" :src="imgBase64" alt="" class="showImg" />
                <div class="uploadbox" v-else>
                  <i class="el-icon-plus"></i>
                </div>
              </el-upload>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 60%; display: flex; margin-top: 46px">
      <div class="mainBtn" @click="hanldeSubmit">确认支付</div>
    </div>
    <el-dialog :visible.sync="ImgdialogVisible" :close-on-click-modal="false">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { bankPay } from "@/api/org";
export default {
  name: "index",
  components: {},
  props: {
    frompath: String,
  },
  data() {
    return {
      uploadToken: "Bearer " + window.sessionStorage.getItem("token"),
      fileList1: [],
      lincenseImg: "",
      dialogImageUrl: "",
      ImgdialogVisible: false,
      userInfo: {},
      timer: null,
      imgFile: null,
      imgBase64: null,
    };
  },

  methods: {
    // faceUploadBefore () {
    //   const isJPG = file.raw.type === 'image/jpeg' || file.type === 'image/jpg';
    //   const isPNG = file.raw.type === 'image/png';
    //   if (!isJPG && !isPNG) {
    //     this.$message.error('只能上传 JPG、JPEG、PNG 格式的图片');
    //     return
    //   }
    // },
    handelChange(file, fileList) {
      const isJPG = file.raw.type === "image/jpeg" || file.type === "image/jpg";
      const isPNG = file.raw.type === "image/png";
      if (!isJPG && !isPNG) {
        this.$message.error("只能上传 JPG、JPEG、PNG 格式的图片");
        return;
      }

      this.imgFile = file.raw;
      this.getBase64(file.raw).then((res) => {
        this.imgBase64 = res;
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    async hanldeSubmit() {
      if (this.imgBase64) {
        let { data } = await bankPay(
          { file: this.imgFile },
          this.$route.query.orderNum
        );
        if (data.code == 200) {
          this.$router.replace(this.frompath);
          this.$notify({
            title: "成功",
            message: "套餐订单提交成功，工作人员会尽快审核",
            type: "success",
          });
        }
      } else {
        this.$notify({
          title: "提醒",
          message: "请先上传支付凭证",
          type: "warning",
        });
      }

      // if (this.timer !== null) {
      //   clearTimeout(this.timer)
      // }
      // this.timer = setTimeout(() => {
      //   if (this.lincenseImg) {
      //     if (this.$route.query.type != 'recharge') {
      //       this.$http
      //         .post('salary-service/customer/order/confirm_payment', {
      //           orderId: this.$route.query.orderNum,
      //           payment: 2,
      //         })
      //         .then((res) => {
      //           if (res.data.code == 200) {
      //             this.$http
      //               .post('salary-service/customer/order/update', {
      //                 id: this.$route.query.orderNum,
      //                 invoice: false,
      //                 payment: 2,
      //                 voucherUrl: this.lincenseImg,
      //               })
      //               .then((res) => {
      //                 this.$store.commit('setPayDone', true)
      //                 this.$router.replace(this.frompath)
      //                 this.$store.commit('setPayDone', false)
      //                 this.$notify({
      //                   title: '成功',
      //                   message: '套餐订单提交成功，工作人员会尽快审核',
      //                   type: 'success',
      //                 })

      //                 this.$store.commit('orderStepBack')
      //               })
      //           } else {
      //             this.$notify({
      //               title: '失败',
      //               message: res.data.msg,
      //               type: 'error',
      //             })
      //           }

      //         })
      //     } else {
      //       this.$http
      //         .post('salary-service/customer/rechargeOrder/confirm_payment', {
      //           orderId: this.$route.query.orderNum,
      //           payment: 2,
      //         })
      //         .then((res) => {
      //           this.$http
      //             .post('salary-service/customer/recharge_order/update', {
      //               id: this.$route.query.orderNum,
      //               invoice: false,
      //               payment: 2,
      //               voucherUrl: this.lincenseImg,
      //             })
      //             .then((res) => {
      //               this.$store.commit('setPayDone', true)
      //               this.$router.replace('/index/new')
      //               this.$store.commit('setPayDone', false)
      //               this.$notify({
      //                 title: '成功',
      //                 message: '充值订单提交成功，工作人员会尽快审核',
      //                 type: 'success',
      //               })
      //             })
      //         })
      //     }
      //   } else {
      //     this.$notify({
      //       title: '失败',
      //       message: '请先上传转账凭证',
      //       type: 'warning',
      //     })
      //   }
      // }, 1000)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.ImgdialogVisible = true;
    },
    handleBefore1() {
      this.$refs.upload1.$children[1].$el.style.display = "none";
      this.$refs.upload1.$children[0].$el.style.display = "inline-block";
    },
    handleSuccess1(response, file, fileList) {
      if (response.code == 200) {
        this.fileList1 = fileList.length;

        this.lincenseImg = response.data;
      } else {
        this.$notify({
          title: "失败",
          message: `${response.message},请删除后重新上传`,
          type: "warning",
        });
      }
    },
    handleRemove1(file, fileList) {
      this.$refs.upload1.$children[0].$el.style.display = "none";
      this.$refs.upload1.$children[1].$el.style.display = "flex";
      this.lincenseImg = "";
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
// .avatar-uploader{
//     height: 100px;
//     width: 100px;
//     background-color: #fff;
//     line-height: 100px;
//     text-align: center;
//     border: 1px dashed #d9d9d9;
//     border-radius: 6px;
//      cursor: pointer;
// }
// .avatar{
//     width: 90px;
//     height: 90px;
// }

.indexs {
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2c2d3a;
    line-height: 28px;
  }

  .infos {
    margin: 16px 0 0 16px;

    div {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 32px;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
      }
    }
  }
}

.buy-title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(44, 45, 58, 1);

  span {
    font-size: 16px;
    font-weight: 400;
    color: rgba(44, 45, 58, 1);
    float: right;
    line-height: 27px;
  }
}

.price {
  padding: 16px 5px;
  font-size: 32px;
  font-weight: 600;
  color: rgba(44, 45, 58, 1);
}

.info-container {
  padding: 22px 31px;
  margin-top: 29px;
  width: 600px;
  height: 296px;
  background: #d4e6ff;
  border-radius: 8px;
  border: 1px dashed #006efe;

  .info-img {
    width: 36px;
  }

  .info-cotent {
    flex: 1;

    h4 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d01410;
      line-height: 32px;
    }

    &-detail {
      display: flex;
      padding-top: 5px;

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 40px;
      }

      &-dt {
        width: 90px;
      }

      &-dd {
        flex: 1;

        p {
          color: #2c2d3a;
        }
      }
    }
  }
}

.next-btn {
  border-radius: 4px !important;
  width: 200px;
  height: 40px;
  border-radius: 20px !important;

  line-height: 40px;
  padding: 0 !important;
  border-color: #fff;
  font-size: 16px !important;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: left;
}

.showImg {
  width: 78px;
  height: 78px;
  object-fit: cover;
}

.btn-container {
  display: block;
  width: 169px;
  height: 40px;
  background: linear-gradient(360deg, #157bd1 0%, #2da1f8 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #1585d8;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  margin-top: 25px;
}

.uploadbox {
  width: 78px;
  height: 78px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e0eaf1;
  color: #e0eaf1;

  i {
    font-size: 30px;
    line-height: 78px;
  }
}
</style>
