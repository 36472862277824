<template>
  <div class="mains">
    <contenttitle v-show="viewActive == 0" title="发票申请"
      :helpUrl="`saas-ban/yong-hu-zhong-xin/fa-piao-guan-li/fa-piao-shen-qing`"></contenttitle>
    <contenttitle v-show="viewActive == 1" title="发票列表"
      :helpUrl="`saas-ban/yong-hu-zhong-xin/fa-piao-guan-li/fa-piao-lie-biao`"></contenttitle>
    <contenttitle v-show="viewActive == 2" title="发票信息管理"
      :helpUrl="`saas-ban/yong-hu-zhong-xin/fa-piao-guan-li/fa-piao-xin-xi-guan-li`"></contenttitle>
    <contenttitle v-show="viewActive == 3" title="地址管理"
      :helpUrl="`saas-ban/yong-hu-zhong-xin/fa-piao-guan-li/di-zhi-guan-li`"></contenttitle>
    <div class="flexContent_wrap">
      <div class="flexContent flexl" style="background-color:#F3F4F8;height:100%;padding:0">
        <div class="space-left">
          <div class="invoice-item" @click="viewActive = index" v-for="(item, index) in  views " :key="item"
            :style="viewActive == index ? 'color: #1990FF;font-weight:600' : ''">{{ item }}</div>
        </div>
        <div class="space-right">
          <apply v-if="viewActive == 0"></apply>
          <invicetable v-if="viewActive == 1"></invicetable>
          <invioceInfo v-if="viewActive == 2"></invioceInfo>
          <invioceaddress v-if="viewActive == 3"></invioceaddress>
        </div>
        <!-- <el-row type="flex" style="width:100%;height:100%">
          <el-col :span="2" class="space-left" style="height:100%">
            <div class="invoice-item" @click="viewActive = index" v-for="(item, index) in  views " :key="item"
              :style="viewActive == index ? 'color: #1990FF;font-weight:600' : ''">{{ item }}</div>
          </el-col>
          <el-col :span="21" style="height:100%;width:100%;">
            <apply v-if="viewActive == 0"></apply>
            <invicetable v-if="viewActive == 1"></invicetable>
            <invioceInfo v-if="viewActive == 2"></invioceInfo>
            <invioceaddress v-if="viewActive == 3"></invioceaddress>
          </el-col>
        </el-row> -->

      </div>
    </div>

    <!-- 
    <div class="space-left">
      <div class="invoice-item" @click="viewActive = index" v-for="(item, index) in  views " :key="item"
        :style="viewActive == index ? 'color: #1990FF' : ''">{{ item }}</div>
    </div>
    <div class="space-right">
      <apply v-if="viewActive == 0"></apply>
      <invicetable v-if="viewActive == 1"></invicetable>
      <invioceInfo v-if="viewActive == 2"></invioceInfo>
      <invioceaddress v-if="viewActive == 3"></invioceaddress>
    </div> -->
  </div>
</template>

<script>
import apply from '@/component/invioce/apply'
import invicetable from '@/component/invioce/invicetable'
import invioceInfo from '@/component/invioce/invioceInfo'
import invioceaddress from '@/component/invioce/invioceaddress'
import contenttitle from "@/component/contentTitle"
export default {
  components: {
    apply, invicetable, invioceInfo, invioceaddress, contenttitle
  },
  data() {
    return {
      views: ['发票申请', '发票列表', '发票信息管理'],
      viewActive: 0,
    }
  },
}
</script>

<style lang="less" scoped>
.mains {
  height: calc(100vh - 56px);
  background: pink;

  .flexContent_wrap {
    height: calc(100vh - 112px);
  }

  .space-left {
    min-width: 160px;
    height: 100%;
    background: #fff;
    padding: 18px 0 0 21px;



    .invoice-item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7);
      line-height: 20px;
      margin-bottom: 27px;
      cursor: pointer;
      user-select: none;
    }
  }

  .space-right {
    flex: 1;
    margin-left: 17px;
    height: 100%;
  }
}
</style>