<template>
  <div class="main">
    <div class="commonTitle" v-if="infoActive" style="margin-bottom: 13px">
      <img src="@/assets/img1/icon/leftto.png" alt="" />
      <div class="buytitle_name">开票信息</div>
    </div>
    <div
      style="float: right; margin-bottom: 15px"
      class="mainBtn"
      @click="addShow"
    >
      添加发票抬头
    </div>
    <el-table
      class="elTable"
      height="calc(100vh - 244px)"
      :row-style="{ height: '56px' }"
      :header-cell-style="headerCellStyle"
      :data="tableData"
    >
      <el-table-column
        type="index"
        label="序号"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="invoiceHeader"
        label="发票抬头"
      ></el-table-column>
      <el-table-column align="center" prop="invoiceType" label="发票类型">
        <template slot-scope="scope">
          {{
            scope.row.invoiceType == 0 ? "电子普通发票" : "电子增值税专用发票"
          }}
        </template></el-table-column
      >
      <el-table-column
        align="center"
        prop="email"
        label="邮箱地址"
      ></el-table-column>
      <el-table-column align="center" prop="createType" label="开具类型">
        <template slot-scope="scope">
          {{ scope.row.createType == 0 ? "企业" : "个人" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="taxpayerId"
        label="税务登记证号"
      ></el-table-column>
      <el-table-column align="center" prop="tacit" label="是否默认">
        <template slot-scope="scope">
          {{ scope.row.tacit ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template slot-scope="scope">
          <div class="operatingbtnBox">
            <div
              class="operatingbtn"
              style="width: 70px"
              v-if="!scope.row.defaultAddress"
              @click="checkdefault(scope.row)"
            >
              设为默认
            </div>
            <div
              class="operatingbtn"
              style="width: 70px"
              v-if="scope.row.defaultAddress"
            >
              默认
            </div>
            <div class="operatingbtn" @click="handleModify(scope.row)">
              修改
            </div>
            <div class="operatingbtn" @click="del(scope.row.id)">删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <draw
      :drawShow="drawShow"
      drawtitle="添加发票抬头"
      @drawconfirm="drawconfirm"
      @drawcancel="drawcancel"
      :width="'500px'"
    >
      <div class="drawContent">
        <el-form
          label-position="left"
          :rules="rules"
          size="small"
          :model="ruleForm"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item label="开具类型：" prop="createType">
            <el-radio-group
              v-model="ruleForm.createType"
              @change="changeCreateType"
            >
              <el-radio :label="0">企业</el-radio>
              <el-radio :label="1">个人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发票抬头：" prop="invoiceHeader">
            <el-input v-model="ruleForm.invoiceHeader"></el-input>
          </el-form-item>
          <el-form-item label="发票类型：" prop="invoiceType">
            <el-radio-group v-model="ruleForm.invoiceType">
              <el-radio :label="0">电子普通发票</el-radio>
              <el-radio :label="1" v-if="ruleForm.createType == 0"
                >电子增值税专用发票</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="税务登记证号："
            prop="taxpayerId"
            v-if="ruleForm.createType == 0"
          >
            <el-input v-model="ruleForm.taxpayerId"></el-input>
          </el-form-item>
          <el-form-item
            label="基本开户银行名称："
            prop="bank"
            v-if="ruleForm.invoiceType == 1"
          >
            <el-input v-model="ruleForm.bank"></el-input>
          </el-form-item>
          <el-form-item
            label="基本开户账号："
            prop="bankAccount"
            v-if="ruleForm.invoiceType == 1"
          >
            <el-input v-model="ruleForm.bankAccount"></el-input>
          </el-form-item>
          <el-form-item
            label="注册场所地址："
            prop="address"
            v-if="ruleForm.invoiceType == 1"
          >
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
          <el-form-item
            label="注册固定电话："
            prop="phone"
            v-if="ruleForm.invoiceType == 1"
          >
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>

          <!-- 都有 -->
          <el-form-item label="邮箱地址：" prop="email">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <!-- 都有 -->

          <el-form-item
            label="一般纳税人证明："
            required
            prop=""
            v-if="ruleForm.invoiceType == 1"
          >
            <!-- <img
              :src="
                showimg ||
                $http.defaults.baseURL +
                  '/bid-check-service/open/image?path=' +
                  ruleForm.img
              "
              alt=""
              class="showimg"
              v-if="ruleForm.img"
            /> -->
            <el-upload
              :show-file-list="false"
              ref="upload"
              :auto-upload="false"
              :on-change="handleChange"
              action="#"
            >
              <img
                :src="
                  showimg ||
                  $http.defaults.baseURL +
                    '/bid-check-service/open/image?path=' +
                    ruleForm.img
                "
                alt=""
                class="showimg"
                v-if="showimg || ruleForm.img"
              />
              <div class="addimg" v-else>
                <i class="el-icon-plus"></i>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </draw>
    <!-- <div class="formtable" v-if="false">
      <div class="formbox">
        <div class="btnbox flexl" v-if="infoActive == true">
          <div class="mainBtn" @click="changeok">修改</div>
          <div class="mainBtnWhite" @click="changeno">取消</div>
        </div>
      </div>
      <div class="mainBtn" @click="changeBtn" v-if="infoActive == false">
        <i class="el-icon-edit-outline"></i>
        修改发票信息
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  clientinvoiceInfo,
  clientinvoiceInfoSave,
  invoiceList,
  invoiceDefault,
  invoiceDelete,
} from "@/api/org.js";
import draw from "@/component/draw";
export default {
  components: {
    draw,
  },
  data() {
    return {
      drawShow: false,
      headerCellStyle: {
        height: "56px",
        background: " #D1E9FF",
        fontSize: "12px",
        color: " #000000",
      },
      tableData: [],
      file: null,
      showimg: "",
      ruleForm: {},
      infoActive: false,
      rules: {
        createType: [
          { required: true, message: "请选择开具类型", trigger: "blur" },
        ],
        invoiceHeader: [
          { required: true, message: "请输入发票抬头", trigger: "blur" },
          {
            pattern: /^\S{2,20}$/,
            message: "请输入正确的发票抬头(2-20个字符)",
            trigger: "blur",
          },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        taxpayerId: [
          { required: true, message: "请输入税务登记证号", trigger: "blur" },
          {
            pattern: /^\S{4,20}$/,
            message: "请输入正确的税务登记证号(5-20个字符)",
            trigger: "blur",
          },
        ],
        bank: [
          {
            required: true,
            message: "请输入基本开户银行名称",
            trigger: "blur",
          },
          {
            pattern: /^\S{4,20}$/,
            message: "请输入正确的基本开户银行名称(4-20个字符)",
            trigger: "blur",
          },
        ],
        //{
        //pattern: /^[0-9]{12,30}$/,
        //message: "请输入正确的基本开户账号",
        //trigger: "blur",
        //},
        bankAccount: [
          { required: true, message: "请输入基本开户账号", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入注册场所地址", trigger: "blur" },
          {
            pattern: /^\S{5,70}$/,
            message: "请输入正确的注册场所地址(5-70个字符)",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入注册固定电话", trigger: "blur" },
          {
            pattern: /^(0\d{2,3}-?)?\d{7,8}(-\d{1,4})?$/,
            message: "请输入正确的注册固定电话",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            pattern:
              /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        // img: [
        //   { required: true, message: '请上传一般纳税人证明', trigger: 'blur' },
        //   // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        // ],
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async del(id) {
      let { data } = await invoiceDelete(id);
      if (data.code == 200) {
        this.$notify({
          title: "成功",
          message: "删除默认抬头成功",
          type: "success",
        });
      }
      this.getInfo();
    },
    handleModify(row) {
      this.ruleForm = { ...row };
      this.drawShow = true;
    },
    async checkdefault(row) {
      let { data } = await invoiceDefault(row.id);
      if (data.code == 200) {
        this.$notify({
          title: "成功",
          message: "设置默认抬头成功",
          type: "success",
        });
      }
      this.getInfo();
    },
    addShow() {
      this.ruleForm = {};
      this.drawShow = true;
    },
    drawconfirm() {
      this.changeok();
    },
    drawcancel() {
      this.drawShow = false;
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    changeCreateType(val) {
      if (val == 1) {
        this.ruleForm.invoiceType = 0;
      }
    },
    handleChange(file, fileList) {
      // console.log(file.raw)
      const isJPG = file.raw.type === "image/jpeg" || file.type === "image/jpg";
      const isPNG = file.raw.type === "image/png";
      if (!isJPG && !isPNG) {
        this.$message.error("只能上传 JPG、JPEG、PNG 格式的图片");
        return;
      }
      this.file = file.raw;
      this.getBase64(file.raw).then((res) => {
        this.showimg = res;
      });
    },
    async getInfo() {
      let { data } = await invoiceList();
      console.log(data);
      this.tableData = data;
      // this.ruleForm = data;
    },
    changeBtn() {
      this.infoActive = true;
    },
    changeno() {
      this.infoActive = false;
      this.getInfo();
    },
    async changeok() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.invoiceType == 1) {
            if (this.file || this.ruleForm.img) {
            } else {
              this.$notify({
                title: "失败",
                message: "请上传一般纳税人证明",
                type: "warning",
              });
              return;
            }
          }
          let { data } = await clientinvoiceInfoSave({
            info: JSON.stringify(this.ruleForm),
            file: this.file,
          });
          if (this.ruleForm.id) {
            if (data.code == 200) {
              this.$notify({
                title: "成功",
                message: "发票信息修改成功",
                type: "success",
              });
            }
          } else {
            if (data.code == 200) {
              this.$notify({
                title: "成功",
                message: "发票信息新增成功",
                type: "success",
              });
            }
          }

          this.drawcancel();
          this.getInfo();
        } else {
          this.$notify({
            title: "失败",
            message: "请将表单信息填写完整",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  height: 100%;
  background: #fff;
  padding: 19px;
  overflow: auto;
}

.formtable {
  display: flex;
  justify-content: space-between;

  .formbox {
    margin-bottom: 20px;

    /deep/.el-form-item {
      margin-bottom: 18px;
    }

    /deep/.el-form-item__label {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }

    .forminfo {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2c2d3a;
    }

    .btnbox {
      margin-top: 0.5vh;

      div {
        padding: 10px 34px;
        margin-right: 10px;
      }
    }
  }

  .changeBtn {
    width: 120px;
    height: 36px;
    background: #1990ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    cursor: pointer;
    text-align: center;
    user-select: none;
  }
}

.showimg {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.addimg {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 100px;
}

.drawContent {
  padding: 20px;
}
</style>