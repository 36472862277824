<template>
  <div>
    <tip></tip>
    <div class="header">
      <img src="@/assets/img1/LOGONEW.png" alt="" />
    </div>
    <div class="content_box">
      <div class="registerBox">
        <div class="title">注册</div>
        <el-form
          :model="ruleForm"
          :hide-required-asterisk="true"
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号:" prop="mobile">
            <el-input
              v-model="ruleForm.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input
              v-model="ruleForm.password"
              type="password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码:" prop="code">
            <div class="getcode">
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入验证码"
              ></el-input>
              <!-- <img :src="codeimg" alt="" @click="getCode"> -->
              <div
                class="codebtn"
                v-if="countdown === 0"
                @click="startCountdown"
                v-loading="codeLoading"
              >
                获取验证码
              </div>
              <div
                class="codebtn"
                v-else
                style="font-size: 14px; font-weight: 400; background: #ccc"
              >
                {{ countdown }} 秒后重新获取
              </div>
            </div>
          </el-form-item>
          <div class="formitem flexl">
            <el-checkbox v-model="checked"> </el-checkbox>
            <div class="xieyi">
              我已阅读并接受<span @click="checkVisible = true"
                >《用户注册协议》</span
              >
            </div>
          </div>
          <div class="formitem">
            <div class="handelRegister" @click="handelRegister">注册</div>
          </div>
          <div class="formitem" style="text-align: center">
            <div class="toLogin" style="font-weight: 600">
              已有账号？<span @click="toLogin">马上登陆</span>
            </div>
          </div>
        </el-form>
      </div>
      <div
        class="toLogin"
        style="font-size: 12px; text-align: center; font-weight: 600"
      >
        不知道如何注册？<span
          @click="linkHelp"
          style="font-size: 12px; color: #006efe; cursor: pointer"
        >
          点击查看注册指南</span
        >
      </div>
    </div>
    <div class="reserved">
      Copyright © 2022-2023 KnownTech. All Rights Reserved. 知也云科技 版权所有
      备案号：<i @click="linkto">鄂ICP备18021828号-7</i>
    </div>

    <el-dialog
      align="center"
      title="用户注册协议"
      :visible.sync="checkVisible"
      :close-on-click-modal="false"
    >
      <div class="reg-text" v-html="content"></div>
      <div style="text-align: center; margin-top: 20px">
        <el-button
          @click="onArgeen()"
          class="login-btn"
          style="width: 350px"
          type="primary"
          >同 意</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  userregisterCode,
  userRegister,
  helplist,
  helpListPage,
  helpDetailById,
  openSessionid,
  openVerifyCode,
  getManagementList,
} from "@/api/saasuser.js";
import tip from "@/component/tip.vue";

export default {
  components: {
    tip,
  },
  data() {
    return {
      codeLoading: false,
      ruleForm: {
        app: "CHECK",
        mobile: "",
        password: "",
        code: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[\w_!@#$%&?._-]{6,16}$/,
            message: "密码为6-16位（字母，数字，!@#$%&?._- ）",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      countdown: 0,
      checked: false,
      checkVisible: false,
      content: "",
      codeimg: "",
      sessionid: "",
      paramsList: {
        //协议
        title: "",
        type: 2,
        pageNumber: 0,
        size: 50,
      },
    };
  },
  mounted() {
    this.ruleForm.mobile = this.$route.query.mobile;
    this.gethelplist();
    this.getCode();
  },
  methods: {
    linkHelp() {
      window.open("https://docs.kicheck.cc/saas-ban/zhu-ce-deng-lu/zhu-ce");
    },
    linkto() {
      window.open("https://beian.miit.gov.cn/");
    },
    async getCode() {
      this.loading = true;
      let res1 = await openSessionid();
      this.sessionid = res1.data;
      this.ruleForm.sessionId = res1.data;
      // let res2 = await openVerifyCode({
      //   sessionid: this.sessionid,
      // })
      // // this.loading = false
      // this.codeimg = 'data:image/png;base64,' + res2.data
    },
    onArgeen() {
      this.checked = true;
      this.checkVisible = false;
    },
    // async gethelplist () {
    //   let { data } = await helplist({ pageNumber: 0, size: 100, title: '查重协议' })
    //   let { data: info } = await helpListPage({ pageNumber: 0, size: 999, catalogId: data[0].id })
    //   let { data: res } = await helpDetailById({ id: info[0].id })
    //   this.content = res.content
    // },
    async gethelplist() {
      let { data } = await getManagementList(this.paramsList);
      let helpDate = data.content.filter(
        (item) => item.title == "用户注册协议"
      );
      this.content = helpDate[0].content;
    },
    handelRegister() {
      if (this.checked) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let { data } = await userRegister(this.ruleForm);
            if (data.code == 200) {
              this.$message({
                message: "注册成功",
                type: "success",
                duration: 1000,
              });
              window.sessionStorage.setItem("token", data.data + "");
              this.$router.push("/index");
            } else {
              this.$notify({
                title: "失败",
                message: data.msg || "注册失败",
                type: "warning",
              });
            }
          }
        });
      } else {
        this.$notify({
          title: "提示",
          message: "请先勾选《用户注册协议》  ",
          type: "warning",
        });
      }
    },
    toLogin() {
      this.$router.push("/login");
    },
    async startCountdown() {
      if (this.codeLoading == true) {
        return;
      }
      let phoneReg = new RegExp(/^1\d{10}$/);
      if (phoneReg.test(this.ruleForm.mobile)) {
        this.codeLoading = true;
        let { data } = await userregisterCode({ mobile: this.ruleForm.mobile });
        if (data.code == 200) {
          this.$notify({
            title: "成功",
            message: "验证码已发送至手机，请注意查收",
            type: "success",
          });
          // 倒计时
          this.countdown = 60; // 设置初始倒计时时间为60秒
          this.codeLoading = false;

          const timer = setInterval(() => {
            this.countdown--; // 每秒减少倒计时时间
            if (this.countdown === 0) {
              clearInterval(timer); // 倒计时结束，清除定时器
            }
          }, 1000);
        } else {
          this.$notify({
            title: "警告",
            message: data.msg || "网络异常",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "请填写正确的手机号码",
          type: "warning",
        });
      }
    },
    getModifyCode(test) {
      let phoneReg = new RegExp(/^1\d{10}$/);
      if (phoneReg.test(test)) {
        this.btnTime = 60;
        this.getCodeLoad = true;
        var timer = setInterval(() => {
          this.btnTime--;
          if (this.btnTime <= 0) {
            this.getCodeLoad = false;
            clearInterval(timer);
          }
        }, 1000);
        this.$http
          .post(`/authority-center/open/register/sms_code?mobile=${test}`)
          .then(
            (res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "成功",
                  message: "验证码已发送至手机，请注意查收",
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "警告",
                  message: res.data.msg || "网络异常",
                  type: "warning",
                });
              }
            },
            (error) => {
              this.btnTime = 0;
            }
          );
      } else {
        this.$message({
          message: "请填写正确的手机号码",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 16px;

  img {
    height: 32px;
  }
}

.content_box {
  height: calc(100vh - 56px);
  background: url("@/assets/img1/loginbackground.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.registerBox {
  width: 600px;
  height: 440px;
  padding: 45px 100px 24px 100px;
  background: #ffffff;
  box-shadow: 0px 2px 22px 0px rgba(197, 197, 197, 0.5);
  border-radius: 20px;
  margin: 80px auto;
  margin-bottom: 23px;

  .formitem {
    padding-left: 70px;
    margin-bottom: 18px;

    .toLogin {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #555555;
      line-height: 20px;

      span {
        color: #1990ff;
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #006efe;
    line-height: 28px;
    text-align: center;
    margin-bottom: 22px;
  }

  .getcode {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 40px;
    }

    /deep/.el-input {
      width: 203px;
    }
  }

  .codebtn {
    width: 126px;
    height: 36px;
    background: #33c8b8;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    // font-weight: 600;
    color: #ffffff;
    line-height: 36px;
    cursor: pointer;
    text-align: center;
    user-select: none;
    margin-left: 10px;
  }

  .xieyi {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    margin-left: 10px;

    span {
      cursor: pointer;
      color: #1990ff;
    }
  }

  .handelRegister {
    width: 327px;
    height: 45px;
    background: #006efe;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
  }

  /deep/.el-form-item {
    margin-bottom: 18px;
  }

  /deep/.el-form-item__label {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }
}

.flexl {
  display: flex;
  align-items: center;
  justify-content: left;
}

.reserved {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 31px;
  left: 0;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #74798c;
  line-height: 17px;

  i {
    cursor: pointer;
    font-size: 12px;
  }
}

/deep/.el-form-item__label {
  font-size: 14px !important;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}

/deep/.el-input__inner {
  font-size: 14px;
  color: #111;
}

// /deep/.inputtext {
//   font-size: 14px;
//   color: #111;
// }
</style>