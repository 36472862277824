<template>
  <div class="indexs">
    <div class=" commonTitle">
      <img src="@/assets/img1/icon/leftto.png" alt="">
      <div class="buytitle_name">订单详情</div>
    </div>
    <div class="infos">
      <div>收款方：彦旭科技（武汉）有限公司</div>
      <div>订单号：{{ this.$route.query.orderNum }}</div>
      <div>
        <div class="flex">
          <div>
            商品名称：{{ this.$route.query.name }}
          </div>
        </div>
      </div>

      <div>支付金额：<span>￥{{ this.$route.query.price }}</span></div>
    </div>
    <div class="img-container" v-loading="isLoading">
      <img :src="pic" alt="">
      <img src="@/assets/img1/wechat.png" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import { setInterval, clearInterval } from 'timers';
export default {
  name: "index",
  props: {
    title: String,
    frompath: String
  },
  components: {},
  data () {
    return {
      userInfo: {},
      pic: '',
      isPayed: false,
      timeId: '',
      isLoading: false,
      sec: 30 * 60,
      secId: '',
      time: ''
    };
  },
  methods: {
    formatSeconds (value) {
      var theTime = parseInt(value);// 需要转换的时间秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      var theTime3 = 0;// 天
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
          if (theTime2 > 24) {
            //大于24小时
            theTime3 = parseInt(theTime2 / 24);
            theTime2 = parseInt(theTime2 % 24);
          }
        }
      }
      var result = '';
      if (theTime > 0) {
        result = "" + parseInt(theTime) + "秒";
      }
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "小时" + result;
      }
      if (theTime3 > 0) {
        result = "" + parseInt(theTime3) + "天" + result;
      }
      return result;
    },
    getWechatInfo () {
      this.isLoading = true
      axios.get(`${this.$http.defaults.baseURL}/bid-check-service/open/wxpay/${this.$route.query.orderNum}`, { responseType: 'blob' }).then(res => {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.pic = e.target.result
          this.isLoading = false
        }
        reader.readAsDataURL(res.data)
      })
    },
    checkPaymentDone () {
      this.$http.get(`bid-check-service/open/wxpayResult/${this.$route.query.orderNum}`).then(res => {
        this.isPayed = res.data.data
      })
    }
  },
  mounted () {
    this.getWechatInfo()
    this.timeId = setInterval(() => {
      if (this.isPayed) {
        clearInterval(this.timeId)
        this.$notify({
          title: "成功",
          message: "订单支付成功",
          type: "success"
        });
        this.$router.push('/index/packageview')

      }
      this.checkPaymentDone()
    }, 5000)

    this.secId = setInterval(() => {
      if (this.sec < 1) {
        clearInterval(this.secId)
        this.$store.commit('setPayDone', true)
        this.$router.replace("/index/order");
        this.$store.commit('setPayDone', false)
        this.$notify({
          title: "失败",
          message: "订单支付已超时，请重新申请",
          type: "warning"
        });
      } else {
        this.sec--
        this.time = this.formatSeconds(this.sec)
      }
    }, 1000)
  },
  destroyed () {
    clearInterval(this.timeId)
    clearInterval(this.secId)
  }

};
</script>

<style lang="less" scoped>
.indexs {

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2c2d3a;
    line-height: 28px;
  }

  .infos {
    margin: 16px 0 0 16px;

    div {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 32px;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
      }
    }
  }
}

.wechat-tips {
  text-align: center;
  font-size: 14px;
  padding: 20px 0;
  justify-content: space-around;
}

.img-container {
  margin: 0 auto;
}

.buy-title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(44, 45, 58, 1);

  span {
    font-size: 16px;
    font-weight: 400;
    color: rgba(44, 45, 58, 1);
    float: right;
    line-height: 27px;
  }
}

.price {
  padding: 16px 5px;
  font-size: 32px;
  font-weight: 600;
  color: rgba(44, 45, 58, 1);
}

.info-container {
  width: 60%;
  min-width: 700px;
  margin: 20px auto;
  background: rgba(243, 245, 247, 1);
  border-radius: 8px;
  border: 2px solid rgba(224, 234, 241, 1);
  padding: 40px;
  display: flex;

  .info-img {
    width: 64px;
  }

  .info-cotent {
    flex: 1;

    h4 {
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 97, 97, 1);
    }

    &-detail {
      display: flex;
      padding-top: 20px;

      p {
        font-size: 16px;
        padding: 5px 0;
      }

      &-dt {
        color: #838f99;
        width: 80px;
      }

      &-dd {
        flex: 1;
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: left;
}
</style>
