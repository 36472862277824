<template>
  <div class="conpanymain">
    <div class="header-wrap">
      <div class="header">
        <div class="checkTime">
          <span class="header-title">
            检测文件：</span>{{ $route.query.title }}
        </div>
        <!-- <div class="headerCenter">
        <div class="checkName">【{{ info.checkDocName }}】</div>
        <div class="vs">对比</div>
        <div class="checkName">【{{ info.targetDocName }}】</div>
      </div> -->
        <div>
          <span class="header-title">
            检测时间：</span>{{ $route.query.dateCreated }}
        </div>
        <div>
          <span class="header-title">
            出现位置：</span> <span style="color:red;font-size:18px"> {{ $route.query.wordCount }}处</span>
        </div>
      </div>
      <el-button @click="downloadPDF" type="primary" class="download" icon="el-icon-download">下载</el-button>

    </div>

    <div class="content">
      <div class="content-left">
        <iframe width="100%" height="100%" :src="`/static/pdf/web/viewer.html?file=${docUrl}`" frameborder="0"
          id="targetIframe" ref="targetIframe">
        </iframe>
      </div>
      <div class="content-right">
        <div class="right-title">
          检测信息：
        </div>
        <div class="info-item">
          <div :style="activeid == item.id ? 'color:red' : ''" v-for="item in detaildata" :key="item.id" class="items"
            @click="search(item)">
            {{ item.senstiveWord }}({{ item.senstiveWordCount }}处)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  sensitiveshow, sensitivereportWord, sensitivereportexport
} from '@/api/check.js'

export default {

  created () {
    this.docid = this.$route.query.docid
    this.targetId = this.$route.query.companyid
    this.rid = this.$route.query.rid
    this.getFile()
  },
  data () {
    return {
      docFile: null,
      docid: '',
      targetId: '',
      docUrl: "",
      rid: '',
      detaildata: null,
      info: {},
      activeid: ''
    }
  },
  methods: {
    async downloadPDF () {
      let { data } = await sensitivereportexport(this.rid, { type: 'word' })
      const $link = document.createElement("a");
      $link.href = URL.createObjectURL(data);
      $link.download = `【${this.$route.query.title}】脱敏检测报告.docx`
      $link.click();
      document.body.appendChild($link);
      document.body.removeChild($link); // 下载完成移除元素
      window.URL.revokeObjectURL($link.href); // 释放掉blob对象
    },
    search (item) {
      if (this.docUrl) {
        this.activeid = item.id
        let iframe = document.getElementById('targetIframe')
        iframe.contentWindow.postMessage(item.senstiveWord, '*')
        iframe.contentWindow.addEventListener(
          'message',
          function (e) {
            iframe.contentWindow.PDFViewerApplication.findBar.findField.value =
              e.data // 要求查询结果全体高亮
            iframe.contentWindow.PDFViewerApplication.findBar.highlightAll.checked = true
            iframe.contentWindow.PDFViewerApplication.findBar.dispatchEvent(
              'highlightallchange'
            )
          },
          false
        )
      } else {
        this.$message({
          type: 'info',
          message: '文件预览加载中，请稍后！',
        })
      }

    },
    async getFile () {
      let { data } = await sensitiveshow(this.docid)
      this.docFile = data
      this.docUrl = window.URL.createObjectURL(this.docFile)

      let { data: detail } = await sensitivereportWord(this.rid)
      console.log(detail, 'ccc')
      this.detaildata = detail
    },
  }

}
</script>

<style lang="less" scoped>
.conpanymain {
  .content {
    height: calc(100vh - 65px);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content-left {
      width: 80%;
      height: 100%;
      overflow: auto;
    }

    .content-right {
      width: 20%;
      height: 100%;
      padding: 15px 45px;

      .right-title {
        font-size: 18px;
        font-weight: 550;



      }

      .info-item {
        .items {
          margin: 15px 0;
          font-size: 14px;
          color: #000;
          font-weight: 500;
          cursor: pointer;
        }

      }

    }
  }

  .header-wrap {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 51px;


    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 65px;
      width: 80%;

      .header-title {
        font-weight: 550;
      }

      .checkTime {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: rgba(0, 0, 0, 0.5);
        line-height: 14px;
      }

      .checkName {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.7);
        line-height: 20px;
      }

      .headerCenter {
        text-align: center;

        .vs {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #909399;
          line-height: 17px;
        }
      }

      .download {
        margin: 0 75px 0 0;
      }
    }
  }
}


.highlight {
  background-color: yellow;
}
</style>