<template>
  <div>
    <readydialog @handelsubmit="delhandelsubmit" @close="delcloseDialog" :show="delshow" :content="'请问是否确认删除此子账号?'">
    </readydialog>
    <readydialog @handelsubmit="handelsubmit" @close="closeDialog" :show="show" :content="'请问是否确认将此账号设为主账号?'">
    </readydialog>
    <div class="main">
      <contenttitle title="账号管理" :helpUrl="`saas-ban/yong-hu-zhong-xin/zhang-hao-guan-li`"></contenttitle>
      <div class="flexContent_wrap">
        <div class="flexContent">
          <div class="flexr">
            <div class="mainBtn" @click="link"><i class="el-icon-link"></i>关联子账号</div>
            <div class="mainBtn" @click="add" style="margin-left:10px"><i class="el-icon-plus"></i> 创建子账号</div>
          </div>
          <el-table max-height="calc(100vh - 250px)" :row-style="{ height: '56px' }" :header-cell-style="headerCellStyle"
            :data="tableData" stripe style="width: 100%;margin-top:15px" empty-text="暂无购买记录">
            <el-table-column prop="username" label="用户名" align="center">
            </el-table-column>
            <el-table-column prop="mobile" label="手机号" align="center">
            </el-table-column>
            <el-table-column prop="dateCreated" label="注册时间" align="center">
              <template slot-scope="scope">
                {{ $moment(scope.row.dateCreated).format('YYYY-MM-DD HH:mm:ss') }}
              </template>
            </el-table-column>admin
            <el-table-column prop="name" label="级别" align="center">
              <template slot-scope="scope">
                {{ scope.row.admin ? "主账号" : "子账号" }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="操作" align="center" width="400">
              <template slot-scope="scope">
                <div v-if="userInfo.admin">
                  <el-button type="text" @click="setadmin(scope.row.id)" v-if="!scope.row.admin">设为主账号</el-button>
                  <el-button type="text" @click="setrole(scope.row)">权限设置</el-button>
                  <el-button type="text" @click="changePassword(scope.row)" v-if="!scope.row.admin">重置密码</el-button>
                  <el-button type="text" @click="update(scope.row)">编辑</el-button>
                  <el-button @click="del(scope.row.id)" type="text" v-if="!scope.row.admin">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <draw :drawShow="drawShow"  :footerShow="footerShow" :drawtitle="drawName[dialogState]" @drawconfirm='drawconfirm' @drawcancel='drawcancel'>
        <div slot class="draw_content">
          <el-form :model="ruleForm" v-if="dialogState == 'add' || dialogState == 'update'" :rules="rules" ref="ruleForm"
            label-width="0" class="demo-ruleForm">
            <div class="drawtitle">手机号：</div>
            <el-form-item prop="mobile">
              <el-input v-model="ruleForm.mobile"></el-input>
            </el-form-item>
            <div class="drawtitle" v-if="dialogState == 'add'">登陆密码：</div>
            <el-form-item prop="password" v-if="dialogState == 'add'">
              <el-input v-model="ruleForm.password" type="password"></el-input>
            </el-form-item>
          </el-form>
          <div v-if="dialogState == 'link'">
            <div class="drawtitle">请输入用户名或手机号：</div>
            <el-input placeholder="请输入用户名或手机号" v-model="linkuser">
              <i slot="suffix" class="el-icon-search" @click="check"
                style="line-height: 40px;margin-right:15px;cursor:pointer"></i>
            </el-input>
            <div class="linkinfo" v-if="findFlag == 1">找到账号，是否关联？</div>
            <div class="linkinfo" v-if="findFlag == 0">未找到匹配到账号</div>
          </div>
          <div v-if="dialogState == 'role'">
            <div class="flexl1">
              <el-radio v-model="roleradio" :label="1">
              </el-radio>
              <div class="rloeTxt">
                <div>所有数据权限</div>
                <div style="margin-top:9px">(可以查看企业所有用户操作的数据)</div>
              </div>
            </div>
            <div class="flexl1">
              <el-radio :disabled="rowdetail.admin" v-model="roleradio" :label="2">
              </el-radio>
              <div class="rloeTxt">
                <div>独立数据权限</div>
                <div style="margin-top:9px">(只查看自己操作的数据)</div>
              </div>
            </div>
          </div>
          <el-form v-if="dialogState == 'resetpassword'" :model="passwordruleForm" :rules="passwordrules"
            ref="passwordruleForm" label-width="0" class="demo-ruleForm">
            <div class="drawtitle">新密码：</div>
            <el-form-item label="" prop="newPassword">
              <el-input v-model="passwordruleForm.newPassword" type="password"></el-input>
            </el-form-item>
            <div class="drawtitle">确认密码：</div>
            <el-form-item label="" prop="newreadyPassword">
              <el-input v-model="passwordruleForm.newreadyPassword" type="password"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </draw>


      <div class="dialogbox">
        <!-- 创建 -->
        <el-dialog :title="dialogType == 'add' ? '创建子账号' : '编辑子账号'" :visible.sync="dialogVisible" width="480px" center>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="手机号：" prop="mobile">
              <el-input v-model="ruleForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="登陆密码：" prop="password" v-if="dialogType == 'add'">
              <el-input v-model="ruleForm.password" type="password"></el-input>
            </el-form-item>
          </el-form>
          <div class="btns">
            <div class="btnok" @click="isok(dialogType)">确定</div>
            <div class="btnno" @click="dialogVisible = false">取消</div>
          </div>
        </el-dialog>
        <el-dialog title="关联子账号" :visible.sync="linkdialogVisible" width="480px" center>
          <div class="linktitle">请输入用户名或手机号：</div>
          <el-input placeholder="请输入用户名或手机号" v-model="linkuser">
            <i slot="suffix" class="el-icon-search" @click="check"
              style="line-height: 40px;margin-right:15px;cursor:pointer"></i>
          </el-input>
          <div class="linkinfo" v-if="findFlag == 1">找到账号，是否关联？</div>
          <div class="linkinfo" v-if="findFlag == 0">未找到匹配到账号</div>
          <div class="btns" v-if="findFlag == 1">
            <div class="btnok" @click="linkok">确定</div>
            <div class="btnno" @click="linkdialogVisible = false">取消</div>
          </div>
          <div class="btns nofind" v-if="findFlag == 0 || findFlag == 2">
            <div class="btnok">确定</div>
            <div class="btnno" @click="linkdialogVisible = false">取消</div>
          </div>
        </el-dialog>
        <el-dialog title="修改密码" :visible.sync="dialogVisiblepassword" width="480px" center :before-close="checkNo">
          <el-form :model="passwordruleForm" :rules="passwordrules" ref="passwordruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-form-item label="新密码：" prop="newPassword">
              <el-input v-model="passwordruleForm.newPassword" type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="newreadyPassword">
              <el-input v-model="passwordruleForm.newreadyPassword" type="password"></el-input>
            </el-form-item>
          </el-form>
          <div class="btns">
            <div class="btnok" @click="changeOk">确定</div>
            <div class="btnno" @click="checkNo">取消</div>
          </div>
        </el-dialog>
        <el-dialog title="权限设置" :visible.sync="dialogVisiblerole" width="480px" center>
          <div class="flexl1">
            <el-radio v-model="roleradio" :label="1">
            </el-radio>
            <div>
              <div>所有数据权限</div>
              <div style="margin-top:9px">（可以查看企业所有用户操作的数据）</div>
            </div>
          </div>
          <div class="flexl1">
            <el-radio  v-model="roleradio" :label="2">
            </el-radio>
            <div>
              <div>独立数据权限</div>
              <div style="margin-top:9px">（只查看自己操作的数据）</div>
            </div>
          </div>
          <div class="btns">
            <div class="btnok" @click="roleisok">确定</div>
            <div class="btnno" @click="dialogVisiblerole = false">取消</div>
          </div>
        </el-dialog>
      </div>
    </div>

  </div>
</template>
<script>
import readydialog from "@/component/readydialog"
import contenttitle from "@/component/contentTitle"
import draw from "@/component/draw"

import { tenantcreate, tenantdelete, tenantupdate, setAccount, userInfo, tenantuserChangepwd } from "@/api/saasuser"
import { tenantPage, checkusername, userassociated, updatemobilemask } from "@/api/org"
export default {
  components: {
    readydialog, contenttitle, draw
  },
  data () {
    return {
      footerShow:true,
      drawName: {
        add: '创建子账号',
        update: '编辑子账号',
        dialogState: '关联子账号',
        role: '权限设置',
        resetpassword: '重置密码'
      },
      dialogState: '',
      drawShow: false,
      setinfo: {},
      show: false,
      delId: "",
      delshow: false,
      findFlag: 2,
      linkuser: '',
      linkdialogVisible: false,
      linkruleForm: {},
      linkrules: [],
      dialogtype: 'add',
      rowdetail: null,
      roleradio: 1,
      headerCellStyle: {
        height: '56px',
        background: ' #D1E9FF',
        color: ' #000000'
      },
      tableData: [],
      dialogType: 'add',
      dialogVisible: false,
      dialogVisiblerole: false,
      dialogVisiblepassword: false,
      passwordruleForm: {},
      roleruleForm: {},
      ruleForm: {},
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3-9]\d{9}$|^14[56]\d{8}$|^15[^4\D]\d{8}$|^16[2567]\d{8}$|^17[0-8]\d{8}$|^18[0-9]\d{8}$|^19[0-35-9]\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^[\w_!@#$%&?._-]{6,16}$/,
            message: '请输入正确的密码',
            trigger: 'blur',
          },
        ],
      },
      passwordrules: {
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^[\w_!@#$%&?._-]{6,16}$/,
            message: '请输入正确的密码',
            trigger: 'blur',
          },
        ],
        newreadyPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
      tableRequest: {
        pageNumber: 1,
        size: 999,
      },
      userInfo: {},
    }
  },
  created () {
    this.getlist()
  },
  methods: {
    drawcancel () {
      this.drawShow = false

    },
    drawconfirm () {
      if (this.dialogState == 'add') {
        this.isok('add')
      }
      if (this.dialogState == 'update') {
        this.isok('update')
      }
      if (this.dialogState == 'link') {
        this.linkok()
      }
      if (this.dialogState == 'role') {
        this.roleisok()
      }
      if (this.dialogState == 'resetpassword') {
        this.changeOk()
      }


    },
    async handelsubmit () {
      let { data } = await setAccount(this.setinfo)
      if (data.code == 200) {
        this.$notify({
          title: "成功",
          message: "设为主账号成功",
          type: "success"
        });
      } else {
        this.$notify({
          type: "error",
          massage: data.msg || "设为主账号失败"
        });
      }
      this.getlist()
      this.show = false
    },
    closeDialog () {
      this.show = false
    },
    async delhandelsubmit () {
      let { data } = await tenantdelete(this.delId)
      if (data.code == 200) {
        this.$notify({
          title: "成功",
          message: "子账号删除成功",
          type: "success"
        });
      } else {
        this.$notify({
          type: "error",
          massage: data.msg || "删除失败,请稍后重试!"
        });
      }
      this.getlist()
      this.delshow = false
    },
    delcloseDialog () {
      this.delshow = false
    },
    async linkok () {
      if (this.findFlag == 2) {
        let { data } = await checkusername({ name: this.linkuser })
        if (data.code == 200) {
        } else {
          this.$message({
            message: '暂未找到需要关联的账户',
            type: 'error',
          })
          return 0
        }
      }
      if (this.findFlag == 0) {
        this.$message({
          message: '暂未找到需要关联的账户',
          type: 'error',
        })
        return 0
      }
      const loading = this.$loading({
        lock: true,
        text: '账号关联中,请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let { data } = await userassociated({ name: this.linkuser })
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '关联成功',
        })
      } else {
        this.$message({
          message: data.msg || '关联失败',
          type: 'error',
        })
      }
      this.drawShow = false
      this.getlist()
      loading.close();
    },

    async check () {
      console.log(this.linkuser)
      let { data } = await checkusername({ name: this.linkuser })
      if (data.code == 200) {
        this.findFlag = 1
      } else {
        this.findFlag = 0
      }
    },
    checkNo () {
      this.$refs['passwordruleForm'].resetFields()
      this.drawShow = false
    },
    changeOk () {
      this.$refs['passwordruleForm'].validate(async (valid) => {
        if (valid) {
          if (this.passwordruleForm.newPassword == this.passwordruleForm.newreadyPassword) {
            let { data } = await tenantuserChangepwd(this.passwordruleForm)
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: '密码重置成功!',
              })
            } else {
              this.$message({
                message: data.msg || '密码重置失败',
                type: 'error',
              })
            }
            this.getlist()
            this.checkNo()
          } else {
            this.$message({
              message: '两次密码输入不一致，请重新输入',
              type: 'error',
            })
          }
        }
      })
    },
    async getUserinfo () {
      let { data } = await userInfo()
      this.userInfo = data
    },
    async roleisok () {
      if (this.roleradio == 1) {
        this.rowdetail.permissionMask = true
      } else {
        this.rowdetail.permissionMask = false
      }
      let { data } = await updatemobilemask({ id: this.rowdetail.id, permissionMask: this.rowdetail.permissionMask })
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '权限设置成功!',
        })
      } else {
        this.$message({
          message: data.msg || '权限设置失败',
          type: 'error',
        })
      }
      this.getlist()
      this.drawShow = false
    },
    setrole (row) {
      this.dialogState = 'role'
      this.rowdetail = { ...row }
      this.drawShow = true
      this.footerShow=row.admin?false:true
      if (row.permissionMask) {
        this.roleradio = 1
      } else {
        this.roleradio = 2
      }
    },
    changePassword (row) {
      this.dialogState = 'resetpassword'
      this.passwordruleForm.username = row.username
      this.drawShow = true
    },
    async setadmin (id) {
      this.setinfo = {
        adminId: '',
        childId: id,
      }
      this.tableData.forEach(item => {
        if (item.admin) {
          this.setinfo.adminId = item.id
        }
      })
      this.show = true
    },
    async update (row) {
      this.dialogState = 'update'
      this.ruleForm = { ...row }
      this.footerShow=true;
      this.drawShow = true
    },
    async del (id) {
      this.delId = id
      this.delshow = true

    },
    async getlist () {
      this.getUserinfo()
      let info = { ...this.tableRequest }
      info.pageNumber = info.pageNumber - 1
      let { data } = await tenantPage(info)
      this.tableData = data
    },
    async isok (type) {
      if (type == 'add') {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            let { data } = await tenantcreate(this.ruleForm)
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: '子账号创建成功!',
              })
            } else {
              this.$message({
                message: data.msg || '子账号创建失败',
                type: 'error',
              })
            }
            this.getlist()
            this.drawShow = false
          }
        })
      } else {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            let { data } = await updatemobilemask({ id: this.ruleForm.id, mobile: this.ruleForm.mobile })
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: '修改成功!',
              })
            } else {
              this.$message({
                message: data.msg || '修改失败',
                type: 'error',
              })
            }
            this.getlist()
            this.drawShow = false
          }
        })
      }
    },
    link () {
      if (this.tableData.length >= 3) {
        this.$message({
          message: "最多存在三个账号",
          type: 'error',
        })
        return 0
      }
      if (this.userInfo.admin) {
        this.findFlag = 2
        this.drawShow = true
        this.dialogState = 'link'
        this.linkruleForm = {}
        this.linkuser = ''
      } else {
        this.$message({
          message: "请使用主账号添加子账号",
          type: 'error',
        })
      }
    },
    add () {
      if (this.tableData.length >= 3) {
        this.$message({
          message: "最多存在三个账号",
          type: 'error',
        })
        return 0
      }
      if (this.userInfo.admin) {
        this.dialogState = 'add'
        this.drawShow = true
        this.ruleForm = {}
      } else {
        this.$message({
          message: "请使用主账号添加子账号",
          type: 'error',
        })
      }

    }
  }
}
</script>

<style lang="less" scoped>
.main {
  position: relative;

  .flexContent_wrap {
    height: calc(100vh - 112px);
  }

  .btnbox {
    margin-bottom: 15px;



    div {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      background: #1990FF;
      color: #FFFFFF;
      user-select: none;

      i {
        margin-right: 5px;
      }
    }
  }
}

/deep/.el-radio__label {
  display: none !important;
}

.flexl1 {
  display: flex;
  justify-content: left;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 14px;
  margin-bottom: 20px;
}

.linktitle {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 22px;
  margin-bottom: 18px;
}

.linkinfo {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #006EFE;
  line-height: 17px;
  margin-top: 20px;
}

/deep/.cell {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #354052;
  line-height: 16px;
}

.draw_content {
  padding: 30px 68px 30px 20px;

  .drawtitle {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 17px;
    margin-bottom: 13px;
  }
}

.rloeTxt {
  div {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 17px;
  }

}
</style>