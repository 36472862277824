<template>
  <div>

    <readydialog @handelsubmit="delhandelsubmit" @close="delcloseDialog" :show="delshow" :content="'请问是否确认删除此地址?'">
    </readydialog>
    <div class="main">
      <div class="top flexsb">
        <div class="toptitle">共{{ tableData.length }}个地址，最多保存10个</div>
        <div class="mainBtn" @click="handleAdd"><i class="el-icon-plus" style="margin-right:8px"></i>新增地址</div>
      </div>
      <el-table class="elTable" height="calc(100vh - 244px)" :row-style="{ height: '56px' }"
        :header-cell-style="headerCellStyle" :data="tableData">
        <el-table-column align="center" prop="receiveName" label="收件人姓名"></el-table-column>
        <el-table-column align="center" prop="receivePhone" label="电话号码"></el-table-column>
        <el-table-column align="center" prop="receiveAddress" label="地址"></el-table-column>
        <el-table-column align="center" prop="receivePostcode" label="邮编"></el-table-column>
        <el-table-column align="center" label="操作" width="180">
          <template slot-scope="scope">
            <div class="operatingbtnBox">
              <div class="operatingbtn" style="width:70px" v-if="!scope.row.defaultAddress"
                @click="checkdefault(scope.row)">设为默认</div>
              <div class="operatingbtn" style="width:70px" v-if="scope.row.defaultAddress">默认</div>
              <div class="operatingbtn" @click="handleModify(scope)">修改</div>
              <div class="operatingbtn" @click="del(scope.row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <draw :drawShow="drawShow" :drawtitle="dialogState != 'modify' ? '新增地址' : '修改地址'" @drawconfirm='drawconfirm'
        @drawcancel='drawcancel'>
        <div slot class="draw_content">
          <el-form :model="form" ref="validateForm" label-width="0" v-if="drawShow">
            <div class="draw_title"><span>*</span>收件人姓名：</div>
            <el-form-item prop="receiveName" :rules="[
              { required: true, message: '请输入收件人姓名', trigger: 'change' },
              {
                pattern: /^[\u4e00-\u9fa5]{2,5}$/,
                message: '收件人姓名为2到5个的汉字',
                trigger: 'change',
              },
            ]">
              <el-input v-model="form.receiveName" autocomplete="off"></el-input>
            </el-form-item>
            <div class="draw_title"><span>*</span>省市区县：</div>

            <el-form-item prop="city" :rules="[
              {
                required: true,
                message: '省市区县不能为空',
                trigger: 'change',
                type: 'array',
              },
            ]">
              <el-cascader style="width: 100%" ref="cascaderAddr" change-on-select clearable v-model="form.city"
                :options="treeData" @change="handleChange"></el-cascader>

            </el-form-item>
            <div class="draw_title"><span>*</span>街道地址：</div>

            <el-form-item prop="receiveAddress" :rules="[
              { required: true, message: '请输入街道地址', trigger: 'change' },
              {
                pattern: /^\S{4,50}$/,
                message: '请输入正确的街道地址(5-50个字符)',
                trigger: 'change',
              },
            ]">
              <el-input v-model="form.receiveAddress" autocomplete="off"></el-input>
            </el-form-item>
            <div class="draw_title"><span>*</span>邮政编码：</div>
            <el-form-item prop="receivePostcode" :rules="[
              { required: true, message: '请输入邮政编码', trigger: 'change' },
              {
                pattern: /^[0-9]{6}$/,
                message: '请输入正确的邮政编码',
                trigger: 'change',
              },
            ]">
              <el-input v-model="form.receivePostcode" autocomplete="off"></el-input>
            </el-form-item>
            <div class="draw_title"><span>*</span>手机号码：</div>
            <el-form-item prop="receivePhone" :rules="[
              { required: true, message: '请输入手机号码', trigger: 'change' },
              {
                pattern: /^1\d{10}$/,
                message: '请输入正确的手机号码',
                trigger: 'change',
              },
            ]">
              <el-input v-model="form.receivePhone" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </draw>

      <!-- 增改弹窗end-->
    </div>
  </div>
</template>

<script>

import readydialog from "@/component/readydialog"
import draw from "@/component/draw"
import { invoiceaddressList, invoiceAddressCreate, invoiceAddressDelete, invoiceaddressDefault } from "@/api/org"
import city from "@/utils/city"
import areas from "@/utils/areas"
import provinces from '@/utils/provinces'
export default {
  components: {
    readydialog, draw
  },
  data () {
    return {

      drawShow: false,
      delId: '',
      delshow: false,
      headerCellStyle: {
        height: '56px',
        background: ' #D1E9FF',
        fontSize: '14px',
        color: ' #000000'
      },
      tableData: [],
      tableRequest: {
        pageNumber: 0,
        size: 20,
      },
      dialogState: 'modify',
      dialogVisible: false,
      form: {
        city: [],
      },
      treeData: {},
      currentLabels: [],
    }
  },
  mounted () {
    this.getlist()
    this.getcity()
  },
  methods: {
    drawconfirm () {
      this.handleSubmit()
    },
    drawcancel () {
      this.drawShow = false
    },
    async delhandelsubmit () {
      let { data } = await invoiceAddressDelete({ ids: this.delId })
      this.$notify({
        title: '成功',
        message: '地址删除成功',
        type: 'success',
      })
      this.getlist()
      this.delshow = false
    },
    delcloseDialog () {
      this.delshow = false
    },
    async checkdefault (row) {
      const { data } = await invoiceaddressDefault(row.id)
      if (data.code == 200) {
        this.$notify({
          title: '成功',
          message: '设为默认地址成功',
          type: 'success',
        })
      } else {
        this.$notify({
          message: data.msg || '删除失败',
          type: 'error',
        })
      }
      this.getlist()

    },
    async del (id) {
      this.delId = id
      this.delshow = true
    },
    handleAdd () {
      this.drawShow = true
      this.form = {}
      this.dialogState = 'add'
    },
    handleChange (val) {
      this.currentLabels =
        this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels
    },
    handleModify (val) {
      let modifyData = JSON.parse(JSON.stringify(val.row))
      console.log(modifyData)
      this.drawShow = true
      this.dialogState = 'modify'
      this.form = { ...modifyData }
      this.form.city = modifyData.code.split(',')
      this.currentLabels = [
        modifyData.province,
        modifyData.city,
        modifyData.county,
      ]
    },
    handleSubmit () {
      this.$refs['validateForm'].validate(async (valid) => {
        if (valid) {
          if (this.currentLabels.length == 3) {
            console.log(this.dialogState)
            switch (this.dialogState) {
              case 'add':

                if (this.tableData.length >= 10) {
                  this.$notify({
                    title: '失败',
                    message: '最多保存10个地址',
                    type: 'warning',
                  })
                  return
                }
                let form = { ...this.form }
                form.province = this.currentLabels[0]
                form.city = this.currentLabels[1]
                form.county = this.currentLabels[2]
                form.defaultAddress = false
                form.code = this.form.city.join()
                const { data } = await invoiceAddressCreate(form)
                if (data.code == 200) {
                  this.form = {}
                  this.$notify({
                    title: '成功',
                    message: '地址新增成功',
                    type: 'success',
                  })
                }
                this.getlist()
                this.drawShow = false
                break
              case 'modify':
                let form1 = { ...this.form }
                form1.province = this.currentLabels[0]
                form1.city = this.currentLabels[1]
                form1.county = this.currentLabels[2]
                form1.defaultAddress = false
                form1.code = this.form.city.join()
                const res = await invoiceAddressCreate(form1)
                if (res.data.code == 200) {
                  this.form = {}
                  this.$notify({
                    title: '成功',
                    message: '地址修改成功',
                    type: 'success',
                  })
                }
                this.getlist()
                this.drawShow = false
                break
            }
          } else {
            this.$notify({
              title: '失败',
              message: '请填写完整的行政区域',
              type: 'warning',
            })
            return false
          }
        } else {
          this.$notify({
            title: '失败',
            message: '请将表单信息填写完整',
            type: 'warning',
          })
        }
      })
    },
    handleCanlce () {
      this.dialogVisible = false
      this.form = {}
    },
    getcity () {
      city.map((citem) => {
        citem.label = citem.name
        citem.value = citem.code
        citem.children = []
      })

      areas.map((citem) => {
        citem.label = citem.name
        citem.value = citem.code
      })
      provinces.map((citem) => {
        citem.label = citem.name
        citem.value = citem.code
        citem.children = []
      })
      provinces.map((pitem) => {
        city.map((citem) => {
          if (citem.parent_code == pitem.code) {
            pitem.children.push(citem)
            areas.map((aitem) => {
              if (aitem.parent_code == citem.code) {
                citem.children.push(aitem)
              }
            })
          }
        })
      })
      this.treeData = provinces
    },
    async getlist () {
      let { data } = await invoiceaddressList(this.tableRequest)
      this.tableData = data.content
    },
    handleClose (done) {
      this.form = {}
      done()
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  height: 100%;
  background: #fff;
  padding: 21px 19px;

  .top {
    margin-bottom: 15px;

    .toptitle {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2C2D3A;
      line-height: 22px;
    }

    .addbtn {
      width: 120px;
      height: 36px;
      background: #1990FF;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 36px;
      cursor: pointer;
      text-align: center;
      user-select: none;
    }
  }

  .btnbox {
    div {
      width: 50px;
      height: 26px;
      border-radius: 4px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      background: linear-gradient(360deg, #157BD1 0%, #2DA1F8 100%);
      border: 1px solid #1585D8;
      color: #FFFFFF;
    }

    div:nth-child(2) {
      background: linear-gradient(360deg, #EAEEF3 0%, #FFFFFF 100%);
      border: 1px solid #CED0DA;
      color: #354052;
    }

  }


  .pagebox {
    margin-top: 15px;
    text-align: right;
  }
}

/deep/.cell {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 16px;
}

/* el-table 宽度自适应 */
.elTable {
  width: 100%;
}

.elTable/deep/ .el-table__header-wrapper table,
.elTable/deep/ .el-table__body-wrapper table {
  width: 100% !important;
}

.elTable /deep/ .el-table__body,
.elTable /deep/ .el-table__footer,
.elTable /deep/ .el-table__header {
  table-layout: auto;
}

.elTable /deep/ .el-table__empty-block,
.elTable/deep/ .el-table__body {
  width: 100% !important;
}

.draw_content {
  padding: 30px 68px 30px 20px;

  .draw_title {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 17px;
    margin-bottom: 13px;

    span {
      color: #E5271F;
    }
  }
}

/deep/.el-cascader-node__label {
  font-size: 12px !important;
}
</style>